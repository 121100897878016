import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../common/Typography';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';

const PartnerManagementHeader = () => {
  const { setCurrState } = usePartnerMgmtContext();

  const go2AddCoBrandForm = () => {
    setCurrState?.('brand-form-add');
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <PageHeadingTypo>Partner Management</PageHeadingTypo>
      <VegaButtonV4
        variant="contained"
        startIcon={<AddCircleOutlineRoundedIcon fontSize="small" />}
        onClick={go2AddCoBrandForm}
      >
        Add Co-Brand Partner
      </VegaButtonV4>
    </Stack>
  );
};

export default PartnerManagementHeader;
