import { Box, Stack, StackProps } from '@mui/material';
import { useClientAuth } from '../../../providers/ClientProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ComponentBody1Typo } from '../Typography';

const ProfileDisplay = (props: StackProps) => {
  const { client } = useClientAuth();
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      {...props}
      className="cursor-pointer"
    >
      <Box>
        <img
          src={client?.logo}
          alt={client?.clientName}
          width={24}
          height={24}
          style={{
            borderRadius: 12,
          }}
        />
      </Box>
      <ComponentBody1Typo>{client?.clientName || 'User'}</ComponentBody1Typo>
      <KeyboardArrowDownIcon fontSize="small" />
    </Stack>
  );
};

export default ProfileDisplay;
