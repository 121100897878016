import { useQuery } from 'react-query';
import { listForClientWithMetaData } from '../../../../../../api/program';
import { VegaProgramCategoryType } from '../../../../../../types/program';

export const usePrograms = (request: {
  clientId: string;
  page: number;
  pageSize: number;
  name?: string;
  status?: string;
  type?: string;
  category?: VegaProgramCategoryType;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [
      'program-list',
      request.page,
      request.pageSize,
      request.status,
      request.type,
      request.category,
      request.name,
    ],
    queryFn: async () => await listForClientWithMetaData(request),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  return { ...data, ...rest };
};
