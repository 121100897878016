/** @format */

import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { fetchAccountForCustomer, listActiveOffer } from '../../api/account';
import { fetchCustomerByCustomerId } from '../../api/customer';
import { GREY, PRIMARY } from '../../constants/style';
import { useSearchHeader } from '../../providers/SearchHeader';
import { VegaCreateCustomersType } from '../../types/customers';
import { Loading, VegaAccordian, VegaCard, VegaTable } from '../common';
import VegaDrawer from '../common/VegaDrawer';
import ViewCustomer from '../customers/ViewCustomer';
import ApplicationDetails from './ApplicationManagement/ApplicationDetails';
import useApplicationMng from './ApplicationManagement/useApplicationMng';
import useOffer from './Offer/useOffer';
import ServiceActions from './Service/ServiceActions';
import useService from './Service/useService';

export const ActiveStateId = {
  APPLICATION_MANAGEMENT: 'APPLICATION_MANAGEMENT',
  OFFERS_MANAGEMENT: 'OFFERS_MANAGEMENT',
  SERVICE_REQUEST_MANAGEMENT: 'SERVICE_REQUEST_MANAGEMENT',
  SEARCH_BY_CUSTOMER: 'SEARCH_BY_CUSTOMER',
};

function SearchByCustomerId1() {
  const { searchValue } = useSearchHeader();
  const [customer, setCustomer] = useState<null | VegaCreateCustomersType>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [offerTableData, setOfferTableData] = useState([]);
  const {
    filterOpts,
    onFilterChange,
    AppMngColumnDef,
    setIsAppDrawerOpen,
    isAppDrawerOpen,
    selectedCustomer,
  } = useApplicationMng();
  const {
    offersColDef,
    isOfferDrawerOpen: open,
    setIsOfferDrawerOpen: setOpen,
    selectedOfferData: selectedData,
  } = useOffer();
  const {
    serviceTableData,
    ServiceMngColDefs,
    isServiceDrawerOpen,
    serviceDrawerState,
    serviceDrawerData,
    setIsServiceDrawerOpen,
    markAquiredHandler,
    fetchDisputes,
  } = useService({
    filterData: {
      key: 'ACCOUNT_ID',
      // value:
      //   selectedProgram?.programId || "2b1c044c-5c0d-493d-bb55-c8ba5081496a",
      value: customer?.account?.accountId || '',
    },
  });

  const getCustomer = () => {
    fetchCustomerByCustomerId(searchValue.toString()).then((res) => {
      setCustomer(res.data);
    });
  };

  const fetchOfferByAccount = (
    accountId: string = customer?.account?.accountId as string
  ) => {
    listActiveOffer({
      accountId: accountId,
    })
      .then((res) => {
        setOfferTableData(res.data);
      })
      .catch((err) => {
        console.log(
          'fetchDataforsearchValue offers for accountid not found ',
          err
        );

        setOfferTableData([]);
      });
  };

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      fetchCustomerByCustomerId(searchValue.toString())
        .then((res) => {
          fetchAccountForCustomer(searchValue.toString())
            .then((res2) => {
              fetchOfferByAccount(res2.data.records[0].accountId);
              setCustomer({
                ...res.data,
                account: res2.data.records[0],
              });
            })
            .catch((err) => {
              console.log('fetchDataforsearchValue account err ', err);
              setCustomer({ ...res.data, account: null });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log('fetchDataforsearchValue error in customer fetch ', err);
          setCustomer(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchValue]);

  return (
    <>
      {!loading ? (
        <VegaCard
          noPad
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box sx={{ p: 3, bgcolor: GREY.light }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Search For - {customer?.customerId}
            </Typography>
            <Typography mt={0.5}>
              Create multi-level, flexible reports at ease.
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection="column" p={4} rowGap={1}>
            {/* ------------------------------------Application Management ------------------------------------ */}
            <VegaAccordian
              defaultExpanded
              header={'Application '}
              content={
                <>
                  {customer ? (
                    <>
                      <VegaCard fullHeight noPad sx={{ mt: 4 }}>
                        <VegaTable
                          data={[customer]}
                          columnDef={AppMngColumnDef}
                          idColumn="customerId"
                        />
                      </VegaCard>
                      <VegaDrawer
                        open={isAppDrawerOpen}
                        onClose={() => setIsAppDrawerOpen(false)}
                        anchor="right"
                        isSubmit={true}
                        isClose={true}
                        onSubmit={() => setIsAppDrawerOpen(false)}
                        submitText="Go It"
                        closeText="Decline"
                        header={
                          <div>
                            Customer ID : {selectedCustomer?.customerId}
                          </div>
                        }
                      >
                        <ApplicationDetails
                          customerId={selectedCustomer?.customerId}
                        />
                      </VegaDrawer>
                    </>
                  ) : (
                    <Box p={2}>
                      No Customer for <b>{searchValue}</b>
                    </Box>
                  )}
                </>
              }
            />

            {/* ------------------------------------Application Management End ------------------------------------ */}

            {/* ------------------------------------Offer Management ------------------------------------ */}

            <VegaAccordian
              defaultExpanded
              header={'Offer'}
              content={
                <>
                  <VegaCard fullHeight noPad sx={{ mt: 4 }}>
                    <VegaTable
                      data={offerTableData}
                      columnDef={offersColDef}
                      idColumn="id"
                    />
                  </VegaCard>
                  <VegaDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    anchor="right"
                    isSubmit={true}
                    isClose={true}
                    onSubmit={() => setOpen(false)}
                    submitText="Go It"
                    closeText="Decline"
                    header={<div>Profile Details</div>}
                  >
                    <ViewCustomer
                      showCustomerData={{ ...selectedData, account: null }}
                    />
                  </VegaDrawer>
                </>
              }
            />

            {/* ------------------------------------Offer Management End------------------------------------ */}

            {/* ------------------------------------Service  Management ------------------------------------ */}

            <VegaAccordian
              header={'Service Management'}
              defaultExpanded
              content={
                <>
                  <VegaCard fullHeight noPad sx={{ mt: 4 }}>
                    <VegaTable
                      data={serviceTableData}
                      columnDef={ServiceMngColDefs}
                      idColumn="id"
                    />
                  </VegaCard>
                  {serviceDrawerState === 'PROFILE' ? (
                    <VegaDrawer
                      open={isServiceDrawerOpen}
                      onClose={() => setIsServiceDrawerOpen(false)}
                      anchor="right"
                      isSubmit={true}
                      isClose={true}
                      onSubmit={() => setIsServiceDrawerOpen(false)}
                      submitText="Go It"
                      closeText="Decline"
                      header="Profile Details"
                    >
                      <ViewCustomer
                        showCustomerData={{
                          ...serviceDrawerData,
                          account: null,
                        }}
                      />
                    </VegaDrawer>
                  ) : (
                    <VegaDrawer
                      open={isServiceDrawerOpen}
                      onClose={() => setIsServiceDrawerOpen(false)}
                      anchor="right"
                      isSubmit={true}
                      // isClose={true}
                      onSubmit={() => setIsServiceDrawerOpen(false)}
                      submitText="Go It"
                      submitButton={
                        <Button
                          sx={{
                            bgcolor: PRIMARY.light,
                            ':hover': { bgcolor: PRIMARY.light },
                            '&.Mui-disabled': {
                              color: PRIMARY.light,
                              bgcolor: PRIMARY.lighter,
                            },
                            color: 'white',
                          }}
                          size="small"
                          onClick={markAquiredHandler}
                          disabled={
                            serviceDrawerData.disputeStatus !== 'RAISED'
                          }
                        >
                          Mark Acquired
                        </Button>
                      }
                      closeText="Decline"
                      header={'SRN- ' + serviceDrawerData.id}
                    >
                      <ServiceActions
                        serviceData={serviceDrawerData}
                        setOpen={setIsServiceDrawerOpen}
                        fetchDisputes={fetchDisputes}
                      />
                    </VegaDrawer>
                  )}
                </>
              }
            />

            {/* ------------------------------------Service  Management End------------------------------------ */}
          </Box>
        </VegaCard>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SearchByCustomerId1;
