import { Box, Stack } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import VegaAutocompleteSearch from '../../common/v4/VegaAutocompleteSearchV4';
import SearchIcon from '../../icon/SearchIcon';
import { useLedgerListQuery } from '../../../store/queries/accountQueries';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import VegaNoResults from '../../common/VegaNoResults';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import { getLedgerListStructuredData } from '../simulatorUtils';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { useCustomerListQuery } from '../../../store/queries/customerQueries';

const LedgerListPage = () => {
  const { clientId } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { data: customerData, isFetching: isFetchingCustomerData } =
    useCustomerListQuery({
      clientId: clientId as string,
      programId: selectedProgram?.programId as string,
      page: 0,
      pageSize: 20,
      allAccounts: false,
    });

  const customerRecords = useMemo(() => {
    return (
      customerData?.records
        .filter((el) => el?.account?.accountId)
        .map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          id: el.account?.accountId as string,
        })) || []
    );
  }, [customerData]);

  const [value, setValue] = useState<(typeof customerRecords)[0] | undefined>(
    customerRecords[0]
  );

  const { data: ledgerListData, isFetching: isFetchingLedgerListData } =
    useLedgerListQuery({
      accountId: value?.id as string,
      page,
      pageSize,
      enabled: Boolean(value?.id),
    });

  const records = value?.id ? ledgerListData?.records : [];

  const updateSearchValue = (
    e: SyntheticEvent<Element, Event>,
    newVal: any
  ) => {
    setValue(newVal);
    setPage(0);
  };

  useEffect(() => {
    setValue(customerRecords[0]);
  }, [customerData, customerRecords]);

  return (
    <Box>
      <VegaHeaderV4 title="Ledger" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={3}>
        <VegaCardV4>
          <VegaAutocompleteSearch
            textFieldProps={TextFieldProps}
            options={customerRecords}
            value={value?.label || ''}
            onChange={updateSearchValue}
          />
        </VegaCardV4>
        {!records?.length &&
        !(isFetchingLedgerListData || isFetchingCustomerData) ? (
          <VegaNoResults
            errorText={
              value
                ? 'No Ledger Found for the customer. Please select another customer'
                : 'No Ledger Found. Please select the customer'
            }
          />
        ) : (
          <>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <VegaSearchfieldV4 />
            </Stack>
            <VegaTableV4
              head={[
                'Ledger Id',
                'Amount',
                'Currency',
                'Transaction Date',
                'Retail Principal',
                'Retail Interest',
                'Retail Fee',
                'Cash Principal',
                'Cash Interest',
                'Cash Fee',
                'Emi Principal',
                'Emi Interest',
                'Emi Fee',
                'Retail Principal Billed',
                'Retail Interest Billed',
                'Retail Fee Billed',
                'Retail Principal Unbilled',
                'Retail Interest Unbilled',
                'Retail Fee Unbilled',
                'Cash Principal Billed',
                'Cash Interest Billed',
                'Cash Fee Billed',
                'Cash Principal Unbilled',
                'Cash Interest Unbilled',
                'Cash Fee Unbilled',
                'Emi Principal Billed',
                'Emi Interest Billed',
                'Emi Fee Billed',
                'Emi Principal Unbilled',
                'Emi Interest Unbilled',
                'Emi Fee Unbilled',
                'Overlimit Unbilled',
              ]}
              data={getLedgerListStructuredData(records)}
              isLoading={isFetchingLedgerListData || isFetchingCustomerData}
              pagination={{
                rowsPerPage: pageSize,
                setRowsPerPage: setPageSize,
                setPage,
                page,
                totalRecords: ledgerListData?.totalItems || 0,
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

const TextFieldProps = {
  header: 'customer',
  standardWidth: 300,
  InputProps: { endAdornment: <SearchIcon /> },
  headerTypo: ListGroupHeadingTypo,
};

export default LedgerListPage;
