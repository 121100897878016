import { Stack } from '@mui/material';
import { VegaCreateCustomersType } from '../../../types/customers';
import VegaDrawerV4 from '../../common/v4/VegaDrawerV4';
import {
  ComponentBody1Typo,
  ListHead2Typo,
  PageSubtitleTypo,
  SectionHeadingTypo,
} from '../../common/Typography';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { Controller, useForm } from 'react-hook-form';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { useEffect, useState } from 'react';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { createCustomerAccount } from '../../../api/account';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

interface ICustomerAccountCreationDrawerProps {
  open: boolean;
  onClose: () => void;
  selectedCustomer: VegaCreateCustomersType | undefined;
  refetchCustomerList: any;
}

export interface IAddAccountFormProps {
  accLimit: number;
  transLimit: number;
}

const CustomerAccountCreationDrawer = ({
  open,
  onClose,
  selectedCustomer,
  refetchCustomerList,
}: ICustomerAccountCreationDrawerProps) => {
  const { selectedProgram } = useProgramData();
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    getValues,
    handleSubmit,
    watch,
  } = useForm<IAddAccountFormProps>({
    mode: 'onTouched',
  });

  const deriveAccountLimit = () => {
    setValue('accLimit', 10000);
    setValue('transLimit', 5000);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const submitForm = () => {
    setLoading(true);
    const formValues = getValues();
    const data = {
      customerId: selectedCustomer?.customerId,
      programId: selectedProgram?.programId as string,
      accountLimit: formValues.accLimit,
      perTransactionLimit: formValues.transLimit,
      accountOriginationMode: 'online',
      billingCycleFrequency: 'MONTHLY',
      currency: 'INR',
      lenderId: '6a087119-bad0-417b-8511-9658bd4d8945',
    };
    createCustomerAccount(data)
      .then((res) => {
        setSnackbar('Account created!');
        refetchCustomerList();
        onClose();
      })
      .catch((err) => setSnackbar(getErrorMessageFromErrorObj(err), 'error'))
      .finally(() => setLoading(false));
  };

  const watchAccLimit = watch('accLimit');

  return (
    <VegaDrawerV4
      header="Create Account"
      onClose={onClose}
      open={open}
      submitBtn={{
        show: true,
        props: {
          value: 'Create Account',
          onClick: handleSubmit(submitForm),
          disabled: (!isDirty && !Boolean(watchAccLimit)) || loading,
          loading,
        },
      }}
      secondaryBtn={{
        props: {
          disabled: loading,
        },
      }}
    >
      <Stack gap={3}>
        <Stack
          gap={2}
          sx={{
            backgroundColor: QUICK_ACCESS.background[100],
            borderRadius: '8px',
            padding: 1.5,
          }}
        >
          <SectionHeadingTypo>Customer Details</SectionHeadingTypo>
          <Stack gap={1.5}>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ width: 100, color: QUICK_ACCESS.text[100] }}
              >
                Name
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {`${
                  selectedCustomer?.firstName
                    ? selectedCustomer?.firstName + ' '
                    : ''
                }` +
                  (selectedCustomer?.lastName
                    ? selectedCustomer?.lastName
                    : '')}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ width: 100, color: QUICK_ACCESS.text[100] }}
              >
                Mobile Number
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer?.mobileNumber}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ width: 100, color: QUICK_ACCESS.text[100] }}
              >
                Email ID
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer?.emailId}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ width: 100, color: QUICK_ACCESS.text[100] }}
              >
                ID Number
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer?.customerId}
              </ComponentBody1Typo>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack gap={0.5}>
            <SectionHeadingTypo>Account Limit</SectionHeadingTypo>
            <PageSubtitleTypo>
              Derive Account Limit from Underwriting
            </PageSubtitleTypo>
          </Stack>
          <VegaButtonV4
            variant="outlined"
            size="small"
            onClick={deriveAccountLimit}
          >
            Derive Account
          </VegaButtonV4>
        </Stack>
        <Stack gap={2}>
          <Controller
            name="accLimit"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.accLimit)}
                header="Account Limit"
                labelText={errors.accLimit?.message}
                standardWidth={400}
                required
                placeholder="Enter Account Limit"
              />
            )}
          />
          <Controller
            name="transLimit"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.transLimit)}
                header="Per Transaction Limit"
                labelText={errors.transLimit?.message}
                standardWidth={400}
                required
                placeholder="Enter Per Transaction Limit"
              />
            )}
          />
        </Stack>
      </Stack>
    </VegaDrawerV4>
  );
};

export default CustomerAccountCreationDrawer;
