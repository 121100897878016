import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
  CircularProgress,
} from '@mui/material';
import { useButtonUtils } from '../../../hooks/useButtonUtils';

export interface IVegaButtonV4Props extends Omit<MuiButtonProps, 'variant'> {
  loading?: boolean;
  loadComponent?: React.ReactElement;
  variant?: ButtonVariants;
}

export type ButtonVariants = 'text' | 'outlined' | 'contained' | 'mellowed';

const VegaButtonV4 = (props: IVegaButtonV4Props) => {
  const {
    children,
    value,
    sx,
    disableRipple,
    loading,
    loadComponent,
    ...rest
  } = props;

  const { variant = 'text', size, disabled } = rest;
  const isTextOrChildPresent = Boolean(value || children);

  const {
    backgroundColor,
    color,
    borderColor,
    backgroundColorHover,
    colorHover,
    borderColorHover,
    opacity,
    padding,
    TEXT_COMPONENT,
  } = useButtonUtils(variant, size, disabled);

  return (
    <MuiButton
      {...rest}
      variant={
        variant === 'mellowed'
          ? 'contained'
          : (variant as MuiButtonProps['variant'])
      }
      disableRipple={disableRipple ?? true}
      sx={{
        backgroundColor,
        opacity,
        width: 'fit-content',
        borderRadius: '4px',
        border: `1px solid ${borderColor}`,
        textTransform: 'none',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: backgroundColorHover,
          color: colorHover,
          border: `1px solid ${borderColorHover}`,
          boxShadow: 'none',
          '.MuiButton-startIcon': {
            color: colorHover,
          },
          '.MuiButton-endIcon': {
            color,
          },
        },
        ':disabled': {
          backgroundColor,
          color,
        },
        '.MuiTypography-root': {
          color,
        },
        ':hover .MuiTypography-root': {
          color: colorHover,
        },
        '.MuiButton-startIcon': {
          color,
          margin: isTextOrChildPresent ? undefined : 0,
        },
        '.MuiButton-endIcon': {
          color,
          margin: isTextOrChildPresent ? undefined : 0,
        },
        minWidth: isTextOrChildPresent ? undefined : 0,
        padding: isTextOrChildPresent ? padding : '6px',
        ...sx,
      }}
    >
      {loading ? (
        loadComponent || (
          <CircularProgress
            sx={{
              width: '15px !important',
              height: '15px !important',
            }}
          />
        )
      ) : (
        <TEXT_COMPONENT>{children || value}</TEXT_COMPONENT>
      )}
    </MuiButton>
  );
};

export default VegaButtonV4;
