import * as React from 'react';
import { SVGProps } from 'react';
import { QUICK_ACCESS } from '../../constants/style';
const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.81006 13.617C3.07261 13.8817 3.42871 14.0305 3.80001 14.0305H12.2C12.5713 14.0305 12.9274 13.8817 13.19 13.617M8.00079 1.97266V9.97266M8.00079 9.97266L11.2008 6.91589M8.00079 9.97266L4.80079 6.91589"
      stroke={props.stroke ?? QUICK_ACCESS.primary.blue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownloadIcon;
