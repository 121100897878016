/** @format */

import axios from 'axios';
import { VegaCreateCustomersType } from '../types/customers';
import { PaginatedResponse } from '../utils/api';
import { BASE_URL, CUSTOMER } from './endpoints';
import { ISupportCustomerDtoProps } from '../types/support';
import { IAuditLogProps } from '../components/customers/ListOfCustomers';

const CUSTOMER_BASE_URL = BASE_URL + CUSTOMER.BASE;
const CUSTOMER_AUDIT_URL = BASE_URL + CUSTOMER.AUDIT;

export const listForCustomers = async (
  programId: string,
  clientId: string,
  page?: any,
  pageSize?: any,
  allAccounts?: boolean
): Promise<PaginatedResponse<VegaCreateCustomersType>> => {
  try {
    const { data } = await axios.get(
      CUSTOMER_BASE_URL + CUSTOMER.LIST_WITH_ACCOUNT,
      {
        params: {
          programId,
          clientId,
          page,
          pageSize,
          allAccounts: !!allAccounts,
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const listCustomersForClient = (
  clientId: string,
  currentMonth = false
) => {
  return axios.get(
    CUSTOMER_BASE_URL + CUSTOMER.LIST.replace('{clientId}', clientId),
    {
      params: { currentMonth },
    }
  );
};

export const createNewCustomer = (customer: VegaCreateCustomersType) => {
  return axios.post(CUSTOMER_BASE_URL, customer);
};

export const filterCustomer = (mobileNumber: string, clientId: any) => {
  return axios.get(CUSTOMER_BASE_URL + '/filter', {
    params: { mobileNumber, clientId },
    data: { mobileNumber: mobileNumber, clientId: clientId },
  });
};

export const fetchCustomerByCustomerId = (customerId: string) => {
  return axios.get(CUSTOMER_BASE_URL + '/' + customerId);
};
export const fetchAuditrByCustomerId = async (
  customerId: string
): Promise<IAuditLogProps[]> => {
  const { data } = await axios.get(
    `${CUSTOMER_AUDIT_URL}?customerId=${customerId}`
  );
  return data;
};

type FilterCustomerData = {
  clientId: string;
  programId: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailId: string;
};

export async function getFilteredCustomers(
  data: Partial<FilterCustomerData>
): Promise<PaginatedResponse<ISupportCustomerDtoProps>> {
  try {
    const endpoint = CUSTOMER_BASE_URL + '/filter';
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
