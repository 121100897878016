import Stack, { StackProps } from '@mui/material/Stack';
import React, { CSSProperties } from 'react';
import { ListGroupHeadingTypo, TextProps } from '../Typography';
import { SxProps, styled } from '@mui/material';
import {
  ACCENT,
  INDICATIVE,
  PRIMARY_COLOR,
  QUICK_ACCESS,
} from '../../../constants/style';

type StatusTagVariant = 'filled' | 'stroked' | 'light-filled' | 'text';
export type StatusTagColor =
  | 'green'
  | 'yellow'
  | 'red'
  | 'orange'
  | 'blue'
  | 'violet'
  | 'grey';

type VegaBadgeStatusTagV4Props = {
  text: string | number;
  color?: StatusTagColor;
  variant?: StatusTagVariant;
  headerTypo?: (props: TextProps) => JSX.Element;
  icon?: React.ReactNode;
  sx?: SxProps;
  overridecolors?: {
    dark: CSSProperties['color'];
    light: CSSProperties['color'];
  };
};

type StyledStackProps = StackProps & {
  color: StatusTagColor;
  variant: StatusTagVariant;
  overridecolors?: {
    dark: CSSProperties['color'];
    light: CSSProperties['color'];
  };
};

const tagColorToDarkColor: Record<StatusTagColor, string> = {
  green: INDICATIVE.green[500],
  yellow: INDICATIVE.yellow[600],
  red: INDICATIVE.red[500],
  orange: PRIMARY_COLOR.orange[600],
  blue: INDICATIVE.blue[600],
  violet: ACCENT.violet[500],
  grey: QUICK_ACCESS.text[75],
};
const tagColorToLightColor: Record<StatusTagColor, string> = {
  green: INDICATIVE.green[50],
  yellow: INDICATIVE.yellow[50],
  red: INDICATIVE.red[50],
  orange: PRIMARY_COLOR.orange[50],
  blue: INDICATIVE.blue[50],
  violet: ACCENT.violet[50],
  grey: QUICK_ACCESS.background[100],
};

function VegaBadgeStatusTagV4({
  text,
  headerTypo,
  icon,
  variant = 'filled',
  color = 'green',
  sx,
  overridecolors,
}: VegaBadgeStatusTagV4Props) {
  const HEADER_TYPO = headerTypo || ListGroupHeadingTypo;
  return (
    <StyledStack
      direction="row"
      className="color-green"
      alignItems="center"
      gap={0.5}
      sx={{
        px: 1,
        py: 0.5,
        borderRadius: '16px',
        ...sx,
      }}
      variant={variant}
      color={color}
      overridecolors={overridecolors}
    >
      {icon}
      <HEADER_TYPO>{text}</HEADER_TYPO>
    </StyledStack>
  );
}

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'color',
})<StyledStackProps>(({ theme, variant, color, overridecolors }) => ({
  boxSizing: 'border-box',
  width: 'fit-content',
  background:
    variant === 'text'
      ? 'transparent'
      : variant === 'filled'
      ? overridecolors?.dark || tagColorToDarkColor[color as StatusTagColor]
      : overridecolors?.light || tagColorToLightColor[color as StatusTagColor],
  border: `1px solid ${
    variant === 'text'
      ? 'transparent'
      : variant === 'light-filled'
      ? overridecolors?.light || tagColorToLightColor[color as StatusTagColor]
      : overridecolors?.dark || tagColorToDarkColor[color as StatusTagColor]
  }`,

  '& .MuiTypography-root': {
    color: `${
      variant === 'filled'
        ? overridecolors?.light || tagColorToLightColor[color as StatusTagColor]
        : overridecolors?.dark || tagColorToDarkColor[color as StatusTagColor]
    } !important`,
  },
}));
export default React.memo(VegaBadgeStatusTagV4);
