import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { IAPIStatus, IErrorStatus } from '../types/commonType';
import { ICustomer } from '../types/customers';
dayjs.extend(localeData);

export function getFormData(object: Record<string, any>) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] != null && object[key] !== undefined) {
      Array.isArray(object[key])
        ? (object[key] as Array<any>).forEach((element) => {
            element != null &&
              element !== undefined &&
              formData.append(key, element);
          })
        : formData.append(key, object[key]);
    }
  });
  return formData;
}

export const getCustomerName = (customer: ICustomer | null) => {
  if (!customer) return '';

  const fName = customer?.firstName || '';
  const mName = customer?.middleName || '';
  const lName = customer?.lastName || '';

  return (fName + ' ' + mName + ' ' + lName).trim();
};

export function getEmptyFields(obj: Object) {
  const emptyFields = Object.entries(obj)
    .filter(([key, value]) => value === '')
    .map(([key]) => key);

  return emptyFields;
}

export const getAPICommonPendingStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: true,
    isError: false,
  } as IAPIStatus;
};

export const getAPICommonRejectedStatus = (
  currentAPIStatus: IAPIStatus = {},
  defErrMsg?: string
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: true,
    errMessage: defErrMsg || 'Unknown Error',
  } as IAPIStatus;
};

export const getAPICommonFulfilledStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: false,
    isFirstCallDone: true,
  } as IAPIStatus;
};

export const standard500ErrResponse = (err?: any) => {
  const errData = err?.response?.data?.apierror;
  return {
    apierror: (errData || {
      status: err?.code,
      message: err?.message,
    }) as IErrorStatus,
  };
};

export const getAllYearsBetween = (
  start: number,
  end?: number,
  rev?: boolean
) => {
  let endUpdate = end;
  if (!endUpdate) {
    endUpdate = new Date().getFullYear();
  }
  if (start >= endUpdate) return [];
  const res = [];
  if (rev) {
    for (let i = endUpdate; i >= start; i--) {
      res.push(i);
    }
  } else {
    for (let i = start; i <= endUpdate; i++) {
      res.push(i);
    }
  }
  return res;
};

export const getAllMonthsBetween = (
  startMonthNum?: number,
  endMonthNum?: number,
  rev?: boolean
) => {
  let months = (dayjs.months() as Array<string>).slice(
    startMonthNum || 0,
    endMonthNum || 12
  );
  if (rev) {
    months = months.reverse();
  }
  return months;
};
