import React from 'react';
import SearchIcon from '../../icon/SearchIcon';
import VegaTextfieldV4, { VegaTextfieldV4Props } from './VegaTextfieldV4';
import { NEUTRALS, QUICK_ACCESS } from '../../../constants/style';

function VegaSearchfieldV4({
  sx,
  wrapperProps,
  InputProps,
  ...rest
}: VegaTextfieldV4Props) {
  return (
    <VegaTextfieldV4
      placeholder="Search..."
      standardWidth={240}
      type="text"
      InputProps={{
        sx: {
          borderRadius: '6px',
        },
        startAdornment: <SearchIcon />,
        ...InputProps,
      }}
      sx={{
        minWidth: {
          xs: 0,
        },
        '& .MuiInputBase-root': {
          pl: 1.5,
          pr: 2,
          py: 1,
          gap: 1,
          bgcolor: 'white',
        },
        '& .MuiInputBase-input': {
          fontFamily: `Aspekta !important`,
          fontSize: '14px',
          lineHeight: '140%',
          color: QUICK_ACCESS.text[200],
          p: 0,
        },
        '& .MuiInputBase-input:placeholder': {
          color: NEUTRALS.grey[400],
        },
        ...sx,
      }}
      wrapperProps={{
        ...wrapperProps,
      }}
      {...rest}
    />
  );
}

export default VegaSearchfieldV4;
