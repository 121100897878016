import axios, { AxiosPromise } from 'axios';
import {
  VegaCreateProgramType,
  VegaDeleteProgramDocument,
  VegaProgramCategoryType,
  VegaProgramDocumentType,
  VegaProgramDocumentUrlRequest,
  VegaProgramType,
  VegaProgramWithMetaDataType,
  VegaSettlementCalendarDate,
  VegaUpdateProgramDocument,
  VegaUpdateProgramType,
} from '../types/program';
import { BASE_URL, PROGRAM, formContentType } from './endpoints';
import { PaginatedResponse } from '../mcc/types/Commons';

const PROGRAM_BASE_URL = BASE_URL + PROGRAM.BASE;
const PROGRAM_KEY_BASE_URL = BASE_URL + PROGRAM.PROGRAM_KEY;

export const listForClient = (
  clientId: string,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10000;
  return axios.get(PROGRAM_BASE_URL + PROGRAM.LIST, {
    params: { clientId, page, pageSize },
  });
};

export const listForClientWithMetaData = (data: {
  clientId: string;
  page?: number;
  pageSize?: number;
  status?: string;
  type?: string;
  category?: VegaProgramCategoryType;
}): AxiosPromise<PaginatedResponse<VegaProgramWithMetaDataType>> => {
  data.page = data.page || 0;
  data.pageSize = data.pageSize || 10000;
  return axios.get(PROGRAM_BASE_URL + PROGRAM.LIST_WITH_META_DATA, {
    params: data,
  });
};

export const getProgramMITCLink = (programId: string) => {
  const endpoint =
    PROGRAM_BASE_URL + PROGRAM.PROGRAM_MITC.replace('{programId}', programId);
  return axios.get(endpoint);
};

export const createNewProgram = (program: VegaCreateProgramType) => {
  return axios.post(PROGRAM_BASE_URL, program, {
    headers: { ...formContentType },
  });
};

export const addProgramKey = (clientId: any, programId: any) => {
  return axios.post(`${PROGRAM_KEY_BASE_URL}/addProgramKey`, {
    clientId,
    programId,
  });
};

export const getProgramKey = (programId: any) => {
  return axios.get(`${PROGRAM_KEY_BASE_URL}/${programId}`);
};

export const updateExistingProgram = (request: VegaUpdateProgramType) => {
  return axios.put(PROGRAM_BASE_URL, request, {
    headers: { ...formContentType },
  });
};

export const updateProgramStatus = (programId: string, status: string) => {
  const data = {
    programId,
    status,
  };
  return axios.patch(PROGRAM_BASE_URL + '/status', data);
};

export const updateProgramSanctionLetter = (
  data: VegaUpdateProgramDocument
) => {
  const formData: FormData = new FormData();
  formData.append('programId', data.programId);
  formData.append('sanctionLetterFormat', data.file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(
    PROGRAM_BASE_URL + PROGRAM.SANCTION_LETTER,
    formData,
    config
  );
};

export const updateProgramAgreementTwo = (data: VegaUpdateProgramDocument) => {
  const formData: FormData = new FormData();
  formData.append('programId', data.programId);
  formData.append('agreementTwo', data.file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(
    PROGRAM_BASE_URL + PROGRAM.AGREEMENT_TWO,
    formData,
    config
  );
};

export const updateProgramLoanEMIAgreement = (
  data: VegaUpdateProgramDocument
) => {
  const formData: FormData = new FormData();
  formData.append('programId', data.programId);
  formData.append('loanEmiAgreement', data.file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(
    PROGRAM_BASE_URL + PROGRAM.LOAN_EMI_AGREEMENT,
    formData,
    config
  );
};

export const deleteProgramAgreementTwo = (data: VegaDeleteProgramDocument) => {
  return axios.delete(
    PROGRAM_BASE_URL +
      PROGRAM.DELETE_AGREEMENT_TWO.replace('{programId}', data.programId)
  );
};

export const deleteProgramSanctionLetter = (
  data: VegaDeleteProgramDocument
) => {
  return axios.delete(
    PROGRAM_BASE_URL +
      PROGRAM.DELETE_SANCTION_LETTER.replace('{programId}', data.programId)
  );
};

export const deleteProgramLoanEMIAgreement = (
  data: VegaDeleteProgramDocument
) => {
  return axios.delete(
    PROGRAM_BASE_URL +
      PROGRAM.DELETE_LOAN_EMI_AGREEMENT.replace('{programId}', data.programId)
  );
};

export const getProgramFormatDocumentUrl = (
  data: VegaProgramDocumentUrlRequest
) => {
  return axios.get(
    PROGRAM_BASE_URL +
      PROGRAM.DOCUMENT_URL.replace('{programId}', data.programId),
    {
      params: {
        documentType: data.documentType,
      },
    }
  );
};

export const getProgramDefaultFormatDocumentUrl = (
  documentType: VegaProgramDocumentType
) => {
  return axios.get(PROGRAM_BASE_URL + PROGRAM.DEFAULT_DOCUMENT_URL, {
    params: {
      documentType: documentType,
    },
  });
};

export const updateProgramBinId = (data: {
  binId: number;
  programId: string;
}) => {
  const endpoint = PROGRAM_BASE_URL + PROGRAM.UPDATE_BIN;
  return axios.patch(endpoint, data);
};

export async function updateProgramMaxPddShiftAllowed(data: {
  value: number;
  programId: string;
}): Promise<VegaProgramType> {
  try {
    const endpoint = PROGRAM_BASE_URL + PROGRAM.UPDATE_MAX_PDD_ALLOWED;
    const response = await axios.post(endpoint, null, {
      params: {
        value: data.value,
        programId: data.programId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function generateSettlementCalendar(data: {
  programId: string;
}): Promise<VegaSettlementCalendarDate[]> {
  try {
    const endpoint = PROGRAM_BASE_URL + PROGRAM.GENERATE_SETTLEMENT_CALENDAR;
    const response = await axios.post(endpoint, null, {
      params: {
        programId: data.programId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSettlementCalendar(data: {
  programId: string;
  financialYear: number;
}): Promise<VegaSettlementCalendarDate[]> {
  try {
    const endpoint = PROGRAM_BASE_URL + PROGRAM.GET_SETTLEMENT_CALENDAR;
    const response = await axios.get(endpoint, {
      params: {
        programId: data.programId,
        financialYear: data.financialYear,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
