import { ISVGProps } from '../../mcc/types/Commons';

const EmailIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? '16'}
      height={props.height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.4001 4.06953L8.0001 7.66953L14.0001 4.06953M3.2001 12.7304C2.31644 12.7304 1.6001 12.0141 1.6001 11.1304V4.86953C1.6001 3.98588 2.31644 3.26953 3.2001 3.26953H12.8001C13.6838 3.26953 14.4001 3.98588 14.4001 4.86953V11.1304C14.4001 12.0141 13.6838 12.7304 12.8001 12.7304H3.2001Z"
        stroke="#1047DC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
