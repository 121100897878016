import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ListHead2Typo, ListTableHead } from '../../common/Typography';
import {
  Box,
  IconButton,
  LinearProgress,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { getAllCloseAccountRequestAPI } from '../../../api/accountClosureAPIs';
import { useClientAuth } from '../../../providers/ClientProvider';
import { AccountClosureStatus } from '../../../enums/account.enum';
import dayjs from 'dayjs';
import { useProgramData } from '../../../providers/ProgramDataProvider';

interface IAccountClosureTableListProps {
  navigate2details: (req_id: string) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7F7',
    color: '#676B76',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: '1px solid #E1E4EB',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:last-child': {
    borderRadius: '8px',
  },
}));

export default function AccountClosureTableList({
  navigate2details,
}: IAccountClosureTableListProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useAppDispatch();
  const { selectedProgram } = useProgramData();

  const { accClosureList, accClosureLoading } = useAppSelector((state) => ({
    accClosureList: state.accountClosure.accountClosureList.payload,
    accClosureLoading:
      state.accountClosure.accountClosureList.apiStatus?.isLoading,
  }));

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const mapStatusValues = (status: AccountClosureStatus) => {
    switch (status) {
      case AccountClosureStatus.APPROVED:
        return { text: 'Approved', color: '#318439' };
      case AccountClosureStatus.PENDING:
        return { text: 'Pending', color: '#C9A907' };
      default:
        return { text: 'Rejected', color: '#A91919' };
    }
  };

  useEffect(() => {
    if (!selectedProgram?.programId) return;

    dispatch(
      getAllCloseAccountRequestAPI({
        payload: {
          programId: selectedProgram.programId,
          page,
          pageSize: rowsPerPage,
        },
      })
    );
  }, [selectedProgram?.programId, page, rowsPerPage, dispatch]);

  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: '#EBEFF4', boxShadow: 'none' }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ border: '1px solid #E1E4EB', borderRadius: '8px' }}>
          <TableRow>
            <StyledTableCell>
              <ListTableHead>Account ID</ListTableHead>
            </StyledTableCell>
            <StyledTableCell>
              <ListTableHead>Request ID</ListTableHead>
            </StyledTableCell>
            <StyledTableCell>
              <ListTableHead>Created On</ListTableHead>
            </StyledTableCell>
            <StyledTableCell>
              <ListTableHead>Transaction Status</ListTableHead>
            </StyledTableCell>
            <StyledTableCell>
              <ListTableHead>Action</ListTableHead>
            </StyledTableCell>
          </TableRow>
          {accClosureLoading && (
            <TableRow sx={{ width: '100%' }}>
              <TableCell colSpan={5} sx={{ padding: 0 }}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {!accClosureList?.records.length && !accClosureLoading && (
            <StyledTableRow sx={{ width: '100%' }}>
              <StyledTableCell
                colSpan={5}
                sx={{ padding: '16px' }}
                align="center"
              >
                No records found
              </StyledTableCell>
            </StyledTableRow>
          )}
          {accClosureList?.records.map((row) => {
            const { text, color } = mapStatusValues(row.status);
            return (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.accountId}
                </StyledTableCell>
                <StyledTableCell>
                  <ListHead2Typo>{row.id}</ListHead2Typo>
                </StyledTableCell>
                <StyledTableCell>
                  <ListHead2Typo>
                    {dayjs(row.createdAt).format('DD/MM/YYYY')}
                  </ListHead2Typo>
                </StyledTableCell>
                <StyledTableCell>
                  <ListHead2Typo sx={{ color }}>{text}</ListHead2Typo>
                </StyledTableCell>
                <StyledTableCell>
                  <ListHead2Typo
                    sx={{ color: '#1047DC' }}
                    className="cursor-pointer"
                    onClick={() => navigate2details(row.id)}
                  >
                    View Details
                  </ListHead2Typo>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
        {Boolean(accClosureList?.records?.length) && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                colSpan={5}
                count={accClosureList?.totalItems || -1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  sx: {
                    fontFamily: 'Aspekta',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#5F6D7E',
                    paddingTop: '4px',
                  },
                  native: true,
                }}
                sx={{
                  '.MuiTablePagination-selectLabel': {
                    fontFamily: 'Aspekta',
                    fontWeight: 600,
                    fontSize: '10px',
                    lineHeight: '12px',
                    color: '#5F6D7E',
                  },
                  '.MuiTablePagination-displayedRows': {
                    fontFamily: 'Aspekta',
                    fontWeight: 600,
                    fontSize: '10px',
                    lineHeight: '12px',
                    color: '#5F6D7E',
                  },
                  border: 'none',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
