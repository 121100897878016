export enum EntityStatusEnum {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
}

export enum CheckerMakerStatusEnum {
  REVIEWER_PENDING = 'REVIEWER_PENDING',
  CHECKER_PENDING = 'CHECKER_PENDING',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum CustomerTypeEnum {
  RETAIL_CUSTOMER = 'RETAIL_CUSTOMER',
  CORPORATE_CUSTOMER = 'CORPORATE_CUSTOMER',
}

export enum CustomerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
}

export enum KycStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum CustomerType {
  DEPENDANT = 'DEPENDANT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum CustomerIdentityTypes {
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  VOTER_ID = 'VOTER_ID',
}

export enum TransferType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CompanyTypeEnum {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PRIVATE_LIMITED = 'PRIVATE_LIMITED',
  PARTNERSHIP_FIRMS = 'PARTNERSHIP_FIRMS',
}

export enum PartnerTypeEnum {
  CO_BRAND = 'CO_BRAND',
  CO_LENDING = 'CO_LENDING',
}

export enum CONSTANTS {
  SELECTED_VIEW = 'view',
}

export enum CARD_REISSUE_REASON {
  BROKEN = 'BROKEN',
  RENAME = 'RENAME',
  CLIENT_ORDER = 'CLIENT_ORDER',
  SHAVED = 'SHAVED',
  RELIEF_LOSS = 'RELIEF_LOSS',
  LOST = 'LOST',
  ROBBED = 'ROBBED',
  THEFT = 'THEFT',
  UNRECEIVED = 'UNRECEIVED',
  DAMAGED = 'DAMAGED',
  DEFECT = 'DEFECT',
  INCORRECT_NAME = 'INCORRECT_NAME',
}

export enum GovernmentIdType {
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum INDIAN_STATES {
  ANDHRA_PRADESH = 'Andhra Pradesh',
  ARUNACHAL_PRADESH = 'Arunachal Pradesh',
  ASSAM = 'Assam',
  BIHAR = 'Bihar',
  CHHATTISGARH = 'Chhattisgarh',
  GOA = 'Goa',
  GUJARAT = 'Gujarat',
  HARYANA = 'Haryana',
  HIMACHAL_PRADESH = 'Himachal Pradesh',
  JHARKHAND = 'Jharkhand',
  KARNATAKA = 'Karnataka',
  KERALA = 'Kerala',
  MADHYA_PRADESH = 'Madhya Pradesh',
  MAHARASHTRA = 'Maharashtra',
  MANIPUR = 'Manipur',
  MEGHALAYA = 'Meghalaya',
  MIZORAM = 'Mizoram',
  NAGALAND = 'Nagaland',
  ODISHA = 'Odisha',
  PUNJAB = 'Punjab',
  RAJASTHAN = 'Rajasthan',
  SIKKIM = 'Sikkim',
  TAMIL_NADU = 'Tamil Nadu',
  TELANGANA = 'Telangana',
  TRIPURA = 'Tripura',
  UTTARAKHAND = 'Uttarakhand',
  UTTAR_PRADESH = 'Uttar Pradesh',
  WEST_BENGAL = 'West Bengal',
  LADAKH = 'Ladakh',
  JAMMU_AND_KASHMIR = 'Jammu and Kashmir',
  PUDUCHERRY = 'Puducherry',
  LAKSHADWEEP = 'Lakshadweep',
  ANDAMAN_AND_NICOBAR_ISLANDS = 'Andaman and Nicobar Islands',
  DNHDD = 'DNHDD',
  CHANDIGARH = 'Chandigarh',
  DELHI = 'Delhi',
}

export enum QUERY_NAMES {
  CUSTOMER_ACCOUNT_LIST = 'customer-account-list',
  LEDGER_LIST = 'ledger-list',
  BILL_LIST = 'bill-list',
  CUSTOMER_AUDIT = 'customer-audit',
  TRANSACTION_LIST_ACCOUNT = 'transaction-list-account',
}

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum TITLES {
  MR = 'Mr.',
  MS = 'Ms.',
  MRS = 'Mrs.',
  DR = 'Dr.',
  PROF = 'Prof.',
}

export enum COUNTRY_CODES {
  INDIA = '+91',
}

export enum STORE_SLICE {
  HEADER = 'header',
  SIMULATOR = 'simulator',
  PROGRAM_MANAGEMENT = 'program_management',
  ACCOUNT_CLOSURE = 'account_closure',
}
