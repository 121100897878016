import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import { SectionHeadingTypo } from '../../common/Typography';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { CARD_REISSUE_REASON } from '../../../enums/commons';
import { makeFirstLetterCapitalAndRestLowerCase } from '../../../utils/stringUtils';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { BASE_URL, CARD_PROCESSOR } from '../../../api/endpoints';
import { useSupport } from '../../../providers/SupportProvider';
import { CardStatus } from '../../../types/support';
import { CardCategory } from '../../../types/card';

const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 30000,
        },
      },
    },
  },
});

const getCardReissueReasons = () => {
  return Object.values(CARD_REISSUE_REASON).map((reason) => {
    return {
      key: reason,
      value: reason
        .split('_')
        .map((word) => makeFirstLetterCapitalAndRestLowerCase(word))
        .join(' '),
    };
  });
};

const cardIssueReasons = getCardReissueReasons();

interface IReissueCardReqDialogProps {
  open: boolean;
  setOpenFalse: () => void;
}

const ReissueCardReqDialog = ({
  open,
  setOpenFalse,
}: IReissueCardReqDialogProps) => {
  const [reissueReason, setReissueReason] = useState<string>(
    cardIssueReasons[0].key
  );
  const { setSnackbar } = useSnackbar();

  const handleReissueReason = (e: SelectChangeEvent<string>) => {
    setReissueReason(e.target.value);
  };

  const { accountData, cardsList } = useSupport();

  const reIssueCard = () => {
    axios
      .post(BASE_URL + CARD_PROCESSOR.BASE + '/replaceCard', {
        accountId: accountData.accountId,
        cardId: (cardsList.data || []).find(
          (card) =>
            card.cardStatus === CardStatus.PERM_BLOCKED &&
            card.cardCategory === CardCategory.PRIMARY_CARD
        )?.id,
        programId: accountData.programId,
        replacementReason: reissueReason,
        entityLevel: 'CLIENT',
        entityId: 'clientId',
      })
      .then(function (res) {
        console.log(res);
      })
      .catch(function (res: AxiosError) {
        setSnackbar(res.message, 'error');
      })
      .finally(() => {
        setOpenFalse();
      });
  };

  return (
    <VegaDialogV4
      open={open}
      title="Reissue Card"
      onClose={setOpenFalse}
      actionBtnSecondaryText={'Submit'}
      actionBtnPrimaryText={'Close'}
      closeBtnRequired
      actionBtnSecondaryProps={{
        onClick: reIssueCard,
      }}
      actionBtnPrimaryProps={{
        onClick: setOpenFalse,
      }}
    >
      <Stack padding={'20px 40px'} gap={2.5}>
        <SectionHeadingTypo>Specify reason for reissue -</SectionHeadingTypo>
        <ThemeProvider theme={theme}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reissueReason}
              label="Reason"
              onChange={handleReissueReason}
              sx={{
                width: 'fit-content',
                minWidth: '200px',
              }}
            >
              {cardIssueReasons.map((item) => (
                <MenuItem
                  key={item.key}
                  value={item.key}
                  sx={{ fontSize: '12px' }}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>
      </Stack>
    </VegaDialogV4>
  );
};

export default ReissueCardReqDialog;
