import { Box, SelectChangeEvent, Stack } from '@mui/material';
import VegaHeaderV4 from '../common/v4/VegaHeaderV4';
import { useMemo, useState } from 'react';
import VegaSelectV4, { VegaSelectOptionType } from '../common/v4/VegaSelectV4';
import {
  getAllMonthsBetween,
  getAllYearsBetween,
} from '../../utils/common.utils';
import { useClientAuth } from '../../providers/ClientProvider';
import { useFinanceInvoiceList } from '../../store/queries/invoiceQueries';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';
import { ListGroupHeadingTypo } from '../common/Typography';
import { INDICATIVE } from '../../constants/style';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import DowloadIcon from '../icon/DowloadIcon';
import VegaNoResults from '../common/VegaNoResults';
import { getPresignedUrl } from '../../api/card';

interface IInvoicePageProps {
  renderState: 'vegapay' | 'third-party';
}

const years: VegaSelectOptionType[] = getAllYearsBetween(
  2018,
  undefined,
  true
).map((el) => ({ id: el, name: 'FY ' + el }));

const months: VegaSelectOptionType[] = getAllMonthsBetween().map((el, idx) => ({
  id: idx,
  name: el,
}));

const InvoicePage = ({ renderState }: IInvoicePageProps) => {
  const [year, setYear] = useState<number>(years[0].id as number);
  const [month, setMonth] = useState<number>(months[0].id as number);
  const { clientId } = useClientAuth();

  const handleYearChange = (e: SelectChangeEvent<string | number>) => {
    setYear(e.target.value as number);
  };

  const handleMonthChange = (e: SelectChangeEvent<string | number>) => {
    setMonth(e.target.value as number);
  };

  const linkOnClick = async (link: string) => {
    const presignedUrlRes = await getPresignedUrl(link);
    if (presignedUrlRes?.data) {
      window.open(presignedUrlRes?.data, '_blank');
    }
  };

  const { data, isFetching } = useFinanceInvoiceList({
    clientId: clientId || '',
    year,
    month,
  });

  const records = useMemo(() => data?.data || [], [data?.data]);

  const structuredData: ITableDataProps[] = useMemo(() => {
    return (
      records.map((rec, idx) => {
        return {
          rowData: [
            {
              value: months[rec.month - 1]?.name,
              type: 'string',
            },
            {
              value: rec.clientId,
              type: 'string',
            },
            {
              value: '12345',
              type: 'string',
            },
            {
              value: '6789',
              type: 'string',
            },
            {
              value: '1/2/2003',
              type: 'string',
            },
            {
              children: (
                <ListGroupHeadingTypo
                  sx={{
                    color:
                      idx % 2 === 0
                        ? INDICATIVE.green[500]
                        : INDICATIVE.red[500],
                  }}
                >
                  {idx % 2 === 0 ? 'PAID' : 'UNPAID'}
                </ListGroupHeadingTypo>
              ),
              type: 'jsx',
            },
            {
              type: 'link',
              children: (
                <VegaButtonV4
                  size="small"
                  startIcon={
                    <Box className="MuiButton-startIcon">
                      <DowloadIcon width="12" height="12" />
                    </Box>
                  }
                  sx={{
                    p: 0,
                  }}
                  onClick={() => linkOnClick(rec.invoiceS3Link)}
                >
                  Download
                </VegaButtonV4>
              ),
            },
          ],
        };
      }) || []
    );
  }, [records]);

  return (
    <Box>
      <VegaHeaderV4
        title={`${
          renderState === 'third-party' ? '3rd Party Invoices' : 'Vegapay'
        } Invoices`}
      />
      <Stack p={3} gap={2.5}>
        <Stack direction="row" justifyContent="flex-start" gap={3}>
          <VegaSelectV4
            onChange={handleYearChange}
            value={year}
            defaultValue={year}
            options={years}
            standardWidth={200}
          />
          <VegaSelectV4
            onChange={handleMonthChange}
            value={month}
            defaultValue={month}
            options={months}
            standardWidth={200}
          />
        </Stack>
        {!Boolean(records.length) && !isFetching ? (
          <VegaNoResults errorText="No Invoices Found for the year and month selected" />
        ) : (
          <VegaTableV4
            head={[
              'Invoice Month',
              'Partner Name',
              'Invoice Number',
              'Amount',
              'Due Date',
              'Status',
              'Action',
            ]}
            data={structuredData}
            isLoading={isFetching}
          />
        )}
      </Stack>
    </Box>
  );
};

export default InvoicePage;
