import { Stack } from '@mui/material';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import { ComponentBody1Typo } from '../../common/Typography';
import {
  CustomerAddress,
  VegaCreateCustomersType,
} from '../../../types/customers';
import { INDICATIVE, QUICK_ACCESS } from '../../../constants/style';
import dayjs from 'dayjs';
import { DATE_FORMATE } from '../../../constants/url';
import { CustomerStatus } from '../../../enums/commons';

interface ICustomerDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  selectedCustomer: VegaCreateCustomersType | undefined;
}

const CustomerDetailsDialog = ({
  open,
  onClose,
  selectedCustomer,
}: ICustomerDetailsDialogProps) => {
  return (
    <VegaDialogV4
      open={open}
      onClose={onClose}
      title="Customer Details"
      sx={{
        '.MuiPaper-root': {
          minWidth: 516,
        },
      }}
      closeBtnRequired
    >
      <Stack py={4} px={5} gap={2}>
        {selectedCustomer && (
          <>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                First Name
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer.firstName}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Last Name
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer.lastName}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Country Code
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer.countryCode}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Mobile Number
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer.mobileNumber}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Email ID
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {selectedCustomer.emailId}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                DOB
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {dayjs(selectedCustomer.dob).format(DATE_FORMATE)}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Gender
              </ComponentBody1Typo>
              <ComponentBody1Typo>{selectedCustomer.gender}</ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Status
              </ComponentBody1Typo>
              <ComponentBody1Typo
                sx={{ color: getStatusColor(selectedCustomer.status) }}
              >
                {selectedCustomer.status}
              </ComponentBody1Typo>
            </Stack>
            <Stack direction="row" gap={2}>
              <ComponentBody1Typo
                sx={{ color: QUICK_ACCESS.text[100], minWidth: 130 }}
              >
                Current Address
              </ComponentBody1Typo>
              <ComponentBody1Typo>
                {strigifyAddress(selectedCustomer.currentAddress)}
              </ComponentBody1Typo>
            </Stack>
          </>
        )}
      </Stack>
    </VegaDialogV4>
  );
};

function getStatusColor(status: CustomerStatus) {
  switch (status) {
    case CustomerStatus.ACTIVE:
      return INDICATIVE.green[600];
    case CustomerStatus.INACTIVE:
      return INDICATIVE.yellow[600];
    case CustomerStatus.BLOCKED:
      return INDICATIVE.red[500];
  }
}

function strigifyAddress(add: CustomerAddress) {
  return (
    add.line1 +
    ', ' +
    (add.line2 ? add.line2 + ', ' : '') +
    add.city +
    ', ' +
    add.state +
    ', ' +
    add.country +
    ' - ' +
    add.pincode
  );
}

export default CustomerDetailsDialog;
