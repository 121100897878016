import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../../common/v4/VegaHeaderV4';
import AddCustomerPersonalDetails1 from './AddCustomerPersonalDetails1';
import AddCustomerPersonalDetails2 from './AddCustomerPersonalDetails2';
import AddCustomerIDDetails from './AddCustomerIDDetails';
import {
  COUNTRY_CODES,
  CustomerStatus,
  CustomerType,
  GENDER,
  GovernmentIdType,
  TITLES,
} from '../../../../enums/commons';
import VegaButtonV4 from '../../../common/v4/VegaButtonV4';
import { useForm } from 'react-hook-form';
import { VegaCreateCustomersType } from '../../../../types/customers';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useState } from 'react';
import { createNewCustomer } from '../../../../api/customer';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useCustomerContext } from '../CustomerWrap';
import AddCustomerAddressDetails from './AddCustomerAddressDetails';

export interface IAddCustomerFormProps {
  personal1: {
    title: TITLES;
    firstName: string;
    middleName?: string;
    lastName: string;
    gender: GENDER;
    dob: string;
  };
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
  };
  personal2: {
    countryCode: COUNTRY_CODES;
    mob: string;
    email: string;
  };
  id: {
    type: GovernmentIdType | '';
    number: string;
    nationality: string;
  };
}

const CustomerCreateCustomerFormPage = () => {
  const { setCurrState } = useCustomerContext();
  const { selectedProgram } = useProgramData();
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const customerFormHook = useForm<IAddCustomerFormProps>({
    mode: 'onTouched',
  });

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    getValues,
  } = customerFormHook;

  const go2CustomerListPage = () => {
    setCurrState?.('customer-list');
  };

  const onClear = () => {
    reset();
  };

  const onFormSubmit = async () => {
    const values = getValues();
    try {
      setIsLoading(true);
      const data: VegaCreateCustomersType = {
        programId: selectedProgram?.programId || '',
        clientId: clientId || '',
        title: values.personal1.title,
        firstName: values.personal1.firstName.trim(),
        lastName: values.personal1.lastName.trim(),
        middleName: values.personal1.middleName
          ? values.personal1.middleName.trim()
          : undefined,
        gender: values.personal1.gender,
        dob: values.personal1.dob,

        countryCode: values.personal2.countryCode,
        mobileNumber: values.personal2.mob,
        emailId: values.personal2.email,

        nationality: values.id.nationality,
        identity: [
          {
            idType: values.id.type,
            idNumber: values.id.number,
            idVerified: 'true',
          },
        ],

        currentAddress: values.address,
        isCurrentAddressPermanent: true,

        status: CustomerStatus.ACTIVE,
        kycStatus: 'VERIFIED',
        customerType: CustomerType.INDIVIDUAL,
      };
      await createNewCustomer({
        ...data,
      });
      setIsLoading(false);
      setSnackbar('Customer Successfully Created!');
      go2CustomerListPage();
    } catch (err) {
      console.error('failed to create customer');
      setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      setIsLoading(false);
    }
  };

  return (
    <Box mb={20}>
      <VegaHeaderV4
        title="Create Customer"
        breadcrumbs={[
          { label: 'Customer', link: go2CustomerListPage },
          { label: 'Create Customer' },
        ]}
        onBackArrowClick={go2CustomerListPage}
      />
      <Stack gap={4.5} p={3}>
        <Stack gap={2}>
          <AddCustomerPersonalDetails1 customerFormHook={customerFormHook} />
          <AddCustomerAddressDetails customerFormHook={customerFormHook} />
          <AddCustomerPersonalDetails2 customerFormHook={customerFormHook} />
          <AddCustomerIDDetails customerFormHook={customerFormHook} />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <VegaButtonV4 onClick={onClear} disabled={isLoading}>
            Clear
          </VegaButtonV4>
          <VegaButtonV4
            variant="contained"
            disabled={!isDirty || isLoading}
            onClick={handleSubmit(onFormSubmit)}
            loading={isLoading}
          >
            Create Customer
          </VegaButtonV4>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomerCreateCustomerFormPage;
