/** @format */

import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';
import { fetchUserData, getClient, updateClientLogo } from '../api/client';
import { getUserByEmail, revokeToken } from '../api/roleManagement';
import { Loading } from '../components/common';
import { ACCESS_MAP, isDev } from '../constants/url';
import { ROUTES, SCOPE_MODULE_ROUTE_MAP } from '../routes';
import {
  ILoggedInUser,
  IVegaClientScope,
  IVegaClientScopeProgramRoles,
  UpdateClientLogoRequestData,
  VegaClientInterface,
  getNameForClientLogoType,
} from '../types/client';
import { VegaUser } from '../types/user';
import { DEFAULT_SCOPE, getParsedScope } from '../utils/clientScope';
import { useSnackbar } from './SnackbarProvider';
import { FOREX_SUB_MODULE_MAP } from '../mcc/role';
import { useCookies } from 'react-cookie';
import { useAppDispatch } from '../store/hook';
import { setShowLendingPage } from '../store/common/stateSlice';

export type ClientContextType = {
  clientId: string | null;
  userId: string | null;
  isAuth: boolean;
  mccUser: boolean;
  forexAdmin: boolean;
  login: (event: any, email: string, password: string) => void;
  logout: () => void;

  client: VegaClientInterface | null;
  scope: IVegaClientScope;
  getCurrentModule: () => string;
  canAccessModule: (module: string) => boolean;
  canAccessSubModule: (subModule: string) => boolean;
  getAllowedProgramRolesForSubmodule: (
    subModule: string
  ) => IVegaClientScopeProgramRoles | null;
  isAdmin: boolean;
  mccPlatformId: string;
  userDetails: any;
  completeNewPassword: (newPassword: string) => void;
  loggedInUserDetails: ILoggedInUser | null;
  updateClientLogo: (logos: UpdateClientLogoRequestData) => void;
  user?: VegaUser | null;
};

type Props = {
  children: React.ReactNode;
};

const ClientContext = React.createContext<ClientContextType | null>(null);
export const useClientAuth = () =>
  React.useContext(ClientContext) as ClientContextType;

const ClientAuthProvider = ({ children }: Props) => {
  // const existingId = sessionStorage.getItem('clientId');
  // const existingUserId = sessionStorage.getItem('userId');
  const dispatch = useAppDispatch();
  const existingId = localStorage.getItem('clientId');
  const existingUserId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const [isAuth, setIsAuth] = useState(!!existingId);
  const [isAdmin, setIsAdmin] = useState(
    // sessionStorage.getItem('isAdmin') === 'true'
    localStorage.getItem('isAdmin') === 'true'
  );

  const [clientId, setClientId] = useState<string | null>(existingId || '');
  const [userId, setUserId] = useState<string | null>(existingUserId || '');
  const [client, setClient] = useState<VegaClientInterface | null>(null);
  const [scope, setScope] = useState<IVegaClientScope>({ ...DEFAULT_SCOPE });
  const [mccUser, setMccUser] = useState<boolean>(false);
  const [forexAdmin, setForexAdmin] = useState<boolean>(false);
  const [mccPlatformId, setMccPlatformId] = useState<string>('');
  const [loadingClientDetails, setLoadingClientDetails] = useState(false);
  const [loginChallange, setLoginChallange] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>({});
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<ILoggedInUser | null>(null);
  const [refreshToken, setRefreshToken] = useState<string>();
  const [user, setUser] = useState<VegaUser | null>(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const tokenRefreshJob = React.useRef<NodeJS.Timeout | null>(null);
  console.log('cookies', cookies);
  useEffect(() => {
    setUserParams();
    return () => {
      stopTokenRefreshJob();
    };
  }, []);

  useEffect(() => {
    if (!client || !loggedInUserDetails) return;
    const updatedLoggedInDetails: ILoggedInUser = {
      email: loggedInUserDetails.email,
      mobileNumber: client.clientMob,
      loginTime: loggedInUserDetails.loginTime,
      idToken: loggedInUserDetails.idToken,
      accessToken: loggedInUserDetails.idToken,
      refreshToken: loggedInUserDetails.idToken,
      name: loggedInUserDetails.name,
      user: loggedInUserDetails?.user,
    };
    setLoggedInUserDetails(updatedLoggedInDetails);
  }, [client]);

  const login = (event: any, email: string, password: string) => {
    event.preventDefault();
    Auth.signIn(email, password)
      .then((user) => {
        setSnackbar(`Login successfull`);
        console.log('Login successfull', user);
        const clientId =
          user.signInUserSession.idToken.payload['custom:clientId'];
        const userId =
          user.signInUserSession.idToken.payload['cognito:username'];
        const isAdmin = true;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.token;
        setRefreshToken(refreshToken);
        // let expires = new Date();
        // setCookie('clientId', clientId, {
        //   secure: true,
        //   httpOnly: true,
        //   sameSite: 'lax',
        // });
        // setCookie('userId', userId, {
        //   secure: true,
        //   httpOnly: true,
        //   sameSite: 'lax',
        // });
        // setCookie('isAdmin', isAdmin, {
        //   secure: true,
        //   httpOnly: true,
        //   sameSite: 'lax',
        // });
        // setCookie('token', accessToken, {
        //   secure: true,
        //   httpOnly: true,
        //   sameSite: 'lax',
        // });

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoginChallange({ forceChange: true, user: user });
          navigate(ROUTES.SET_NEW_PASSWORD);
        } else {
          setUserParams();
        }
      })
      .catch((error) => {
        setSnackbar(`${error.message}`, 'error');
        console.error('Cannot login', error);
      });
  };
  const startTokenRefreshJob = () => {
    if (!tokenRefreshJob.current) {
      console.log(
        'Starting background JOB to refresh access token every 6 hours'
      );
      const tokenInterval = setInterval(refreshAccessToken, 1000 * 60 * 60 * 6);
      tokenRefreshJob.current = tokenInterval;
    }
  };

  const stopTokenRefreshJob = () => {
    clearInterval(tokenRefreshJob.current as NodeJS.Timeout);
    tokenRefreshJob.current = null;
  };

  const refreshAccessToken = () => {
    Auth.currentSession()
      .then((data: any) => {
        console.log('Current Session : ', data);
      })
      .catch((error: any) => {
        console.error('Failed to fetch current session', error);
        logout();
      });
  };

  const completeNewPassword = async (newPassword: string) => {
    if (loginChallange?.forceChange) {
      try {
        let user = loginChallange.user;
        try {
          const { requiredAttributes } = user.challengeParam;
          user = await Auth.completeNewPassword(
            loginChallange.user as CognitoUser,
            newPassword,
            requiredAttributes
          );
          console.log(user);
          setUserParams();
          navigate(ROUTES.LOGIN);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log('Failed to update', e);
      }
    }
  };

  const setUserParams = async () => {
    setLoadingCurrentUser(true);
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        if (user) {
          console.log('Logged in user', user);
          const clientId =
            user.signInUserSession.idToken.payload['custom:clientId'];
          const mccUser =
            user.signInUserSession.idToken.payload['custom:mccUser'];
          const forexAdmin =
            user.signInUserSession.idToken.payload['custom:forexAdmin'];
          const userId =
            user.signInUserSession.idToken.payload['cognito:username'];
          const isAdmin = true;
          const scopeStr =
            user.signInUserSession.idToken.payload?.['custom:scope']; // MCC login
          const loggedInUserEmail =
            user.signInUserSession.idToken.payload?.['email'];
          const loggedInTime = new Date(
            user.signInUserSession.idToken.payload['auth_time'] * 1000
          );
          const idToken = user.signInUserSession.idToken.jwtToken;
          const accessToken = user.signInUserSession.accessToken.jwtToken;
          const refreshToken = user.signInUserSession.refreshToken.token;
          const exp = new Date(
            user.signInUserSession.idToken.payload['exp'] * 1000
          );
          const scopeObj = getParsedScope(scopeStr);
          const isMccUser = mccUser === 'true' ? true : false;
          const isForexAdmin = forexAdmin === 'true' ? true : false;
          setRefreshToken(refreshToken);
          setMccUser(isMccUser);
          setForexAdmin(isForexAdmin);
          setClientId(clientId);
          fetchClient(clientId, isMccUser);
          console.log('first', mccPlatformId, scopeObj.scope, mccUser);
          if (!mccPlatformId && scopeObj.scope && mccUser) {
            const roles = scopeObj.scope[Object.keys(scopeObj.scope)?.[0]];
            let branchIdFromRole = roles[Object.keys(roles)?.[0]];
            const bId = Object.keys(branchIdFromRole)?.[0];
            console.log(
              '🚀 ~ file: ClientProvider.tsx:209 ~ .then ~ bId:',
              bId
            );
            setMccPlatformId(bId ?? '');
          }
          setIsAuth(true);
          setIsAdmin(isAdmin);
          setScope(scopeObj);
          setLoginChallange(null);
          // sessionStorage.setItem('clientId', clientId);
          // sessionStorage.setItem('userId', userId);
          // sessionStorage.setItem('isAdmin', `${isAdmin}`);
          // sessionStorage.setItem('scope', `${scopeObj}`);
          // sessionStorage.setItem('token', `${accessToken}`);

          localStorage.setItem('clientId', clientId);
          localStorage.setItem('userId', userId);
          localStorage.setItem('isAdmin', `${isAdmin}`);
          localStorage.setItem('scope', `${scopeObj}`);
          localStorage.setItem('token', `${accessToken}`);
          // let expires = new Date();
          // setCookie('clientId', clientId, {
          //   secure: true,
          //   httpOnly: true,
          //   sameSite: 'lax',
          // });
          // setCookie('userId', userId, {
          //   secure: true,
          //   httpOnly: true,
          //   sameSite: 'lax',
          // });
          // setCookie('isAdmin', isAdmin, {
          //   secure: true,
          //   httpOnly: true,
          //   sameSite: 'lax',
          // });
          // setCookie('token', accessToken, {
          //   secure: true,
          //   httpOnly: true,
          //   sameSite: 'lax',
          // });
          await fetchUserDetailsAndSetLoggedInUser({
            email: loggedInUserEmail,
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            loggedInTime: loggedInTime,
          });
          await getUserData(loggedInUserEmail);
          startTokenRefreshJob();
        }
      })
      .catch((err) => {
        console.error('failed to fetch current user', err);
      })
      .finally(() => {
        setLoadingCurrentUser(false);
      });
  };

  const getUserData = async (email: string) => {
    fetchUserData(email)
      .then((response) => {
        console.log('response?.data', response?.data);
        setUserDetails(response?.data);
        if (response?.data) {
          setMccUser(response?.data?.mccUser === true);
        }
        setUserId(response?.data?.userId ?? '');
      })
      .catch((err) => {
        console.error('failed to fetch current user data', err);
      });
  };

  function fetchClient(clientId: string, mccUser: boolean) {
    if (!!clientId && !loadingClientDetails) {
      setLoadingClientDetails(true);

      getClient(clientId)
        .then((res) => setClient(res.data))
        .catch((err) => {
          setSnackbar('Failed to fetch  client', 'error');
        })
        .finally(() => setLoadingClientDetails(false));
    }
  }

  const fetchUserDetailsAndSetLoggedInUser = async (data: {
    email: string;
    idToken: string;
    accessToken: string;
    refreshToken: string;
    loggedInTime: Date;
  }) => {
    try {
      const response = await getUserByEmail({ email: data.email });

      setLoggedInUserDetails({
        email: data.email,
        loginTime: data.loggedInTime,
        idToken: data.idToken,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        name: response.name,
        user: response,
      });
      const isAdmin = response.isAdmin;
      setIsAdmin(isAdmin);
      setUser(response);
    } catch (error) {
      console.error('Failed to get user details by email=> ', error);
      setLoggedInUserDetails({
        email: data.email,
        loginTime: data.loggedInTime,
        idToken: data.idToken,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      });
      setIsAdmin(false);
      setUser(null);
    }
  };

  async function revokeAccessTokens() {
    if (!refreshToken) return;
    try {
      const response = await revokeToken(refreshToken);
      console.log('Token Revoked', refreshToken);
    } catch (error) {
      console.error(error);
    }
  }

  const logout = async () => {
    await revokeAccessTokens();
    Auth.signOut();
    stopTokenRefreshJob();
    setClientId(null);
    setClient(null);
    setIsAuth(false);
    sessionStorage.clear();
    localStorage.clear();
    removeCookie('clientId');
    removeCookie('userId');
    removeCookie('isAdmin');
    removeCookie('token');
    navigate(ROUTES.LOGIN);
    dispatch(setShowLendingPage(true));
  };

  const getNormalisedModuleString = (module: string | undefined) => {
    if (module) {
      return module.replace('/', '').toUpperCase();
    }
    return '';
  };

  const getCurrentModule = () => {
    const currentHost = window.location.hostname;
    let module = isDev
      ? 'hq'
      : getNormalisedModuleString(ACCESS_MAP[currentHost]);
    console.log('current modeule: ', module);
    if (
      (currentHost === 'client.vegapay.tech' ||
        currentHost === 'www.client.vegapay.tech') &&
      mccUser
    ) {
      module = 'hq';
    }
    console.log('current modeule: ', module);
    if (SCOPE_MODULE_ROUTE_MAP[module]) {
      console.log(
        'current modeule: ',
        getNormalisedModuleString(SCOPE_MODULE_ROUTE_MAP[module])
      );
      return getNormalisedModuleString(SCOPE_MODULE_ROUTE_MAP[module]);
    }

    return getNormalisedModuleString(module);
  };

  const canAccessModule = (module: string) => {
    const normalisedModuleStr = getNormalisedModuleString(module);

    if (
      !!scope?.scope ||
      scope.scope?.[normalisedModuleStr] ||
      scope.scope['ALL']
    ) {
      if (forexAdmin) {
        return true;
      }
      if (mccUser) {
        return scope.scope?.[normalisedModuleStr] ? true : false;
      }
      return true;
    }
    return false;
  };

  const canAccessSubModule = (subModule: string) => {
    const module = getCurrentModule();
    if (!canAccessModule(module) && !isDev) {
      return false;
    }
    console.log('module: ', module);
    console.log('scope.scope: ', scope.scope);
    const normalisedSubModule = getNormalisedModuleString(subModule);

    const moduleObj = scope.scope[module] || scope.scope['ALL'];

    if (!moduleObj) {
      return true;
    }
    if (
      moduleObj[normalisedSubModule] ||
      moduleObj['ALL'] ||
      moduleObj?.[FOREX_SUB_MODULE_MAP?.[module]?.[normalisedSubModule]]
    ) {
      return true;
    }
    return false;
  };

  const getAllowedProgramRolesForSubmodule = (subModule: string) => {
    const module = getCurrentModule();
    if (!canAccessModule(module) || !canAccessSubModule(subModule)) {
      return null;
    }
    try {
      const normalisedSubModule = getNormalisedModuleString(subModule);
      console.log(
        'Scope str in program check: ',
        scope,
        module,
        normalisedSubModule
      );
      const allowedPrograms = scope.scope[module]
        ? scope.scope[module][normalisedSubModule]
          ? scope.scope[module][normalisedSubModule]
          : scope.scope[module]['ALL']
        : scope.scope['ALL'][normalisedSubModule]
        ? scope.scope['ALL'][normalisedSubModule]
        : scope.scope['ALL']['ALL'];
      return allowedPrograms;
    } catch {
      return { ALL: ['EDIT'] };
    }
  };

  function _updateClientLogo(logoData: UpdateClientLogoRequestData) {
    return new Promise((resolve, reject) => {
      updateClientLogo(logoData)
        .then((response) => {
          const updatedClient = response.data as VegaClientInterface;
          setClient(updatedClient);
          setSnackbar('Updated ' + getNameForClientLogoType(logoData.logoType));
          resolve(updatedClient);
        })
        .catch((error) => {
          setSnackbar('Failed to update Logo ' + error, 'error');
          reject(error);
        });
    });
  }

  useEffect(() => {
    console.log('================Update Refresh Token====================');
    console.log({
      refreshToken: refreshToken,
    });
    console.log('====================================');
  }, [refreshToken]);

  return (
    <ClientContext.Provider
      value={{
        userDetails,
        clientId,
        userId,
        mccUser,
        forexAdmin,
        isAuth,
        login,
        logout,
        client,
        mccPlatformId,
        isAdmin,
        scope,
        getCurrentModule,
        canAccessModule,
        canAccessSubModule,
        completeNewPassword,
        getAllowedProgramRolesForSubmodule,
        loggedInUserDetails,
        updateClientLogo: _updateClientLogo,
        user: user,
      }}
    >
      {loadingCurrentUser || loadingClientDetails ? <Loading /> : children}
    </ClientContext.Provider>
  );
};

export default ClientAuthProvider;
