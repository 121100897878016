import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import VegaAutocompleteSearchV4 from '../../common/v4/VegaAutocompleteSearchV4';
import { useTransactionAccountListQuery } from '../../../store/queries/accountQueries';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import SearchIcon from '../../icon/SearchIcon';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import {
  getTransactionAccListStructuredData,
  getTransactionDetailsDialogData,
} from '../simulatorUtils';
import VegaNoResults from '../../common/VegaNoResults';
import VegaDrawerV4 from '../../common/v4/VegaDrawerV4';
import { TransactionDto } from '../../../types/transaction';
import VegaDetailsDialogV4 from '../../common/v4/VegaDetailsDialogV4';
import TransactionDebitDrawer from './TransactionDebitDrawer';
import TransactionRepaymentDrawer from './TransactionRepaymentDrawer';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { useCustomerListQuery } from '../../../store/queries/customerQueries';

const TransactionListPage = () => {
  const { clientId } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const [openDebitTransactionDrawer, setOpenDebitTransactionDrawer] =
    useState(false);
  const [openRepaymentDrawer, setOpenRepaymentDrawer] = useState(false);
  const [openTransactionDetailsDialog, setOpenTransactionDetailsDialog] =
    useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    TransactionDto | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { data: customerData } = useCustomerListQuery({
    clientId: clientId as string,
    programId: selectedProgram?.programId as string,
    page: 0,
    pageSize: 20,
    allAccounts: false,
  });

  const updateRepaymentDrawer = (val: boolean) => () => {
    setOpenRepaymentDrawer(val);
  };

  const updateDebitTransactionDrawer = (val: boolean) => () => {
    setOpenDebitTransactionDrawer(val);
  };

  const transactionDetailsDialogOpen = (rec: TransactionDto) => {
    setSelectedTransaction(rec);
    setOpenTransactionDetailsDialog(true);
  };

  const transactionDetailsDialogClose = () => {
    setOpenTransactionDetailsDialog(false);
  };

  const customerRecords = useMemo(() => {
    return (
      customerData?.records
        .filter((el) => el?.account?.accountId)
        .map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          id: el.account?.accountId as string,
        })) || []
    );
  }, [customerData]);

  const [value, setValue] = useState<(typeof customerRecords)[0] | undefined>(
    customerRecords[0]
  );

  const {
    data: transactionData,
    isFetching,
    refetch,
  } = useTransactionAccountListQuery({
    accountId: value?.id as string,
    page,
    pageSize,
  });

  const records = value?.id ? transactionData?.records : [];

  const updateSearchValue = (
    e: SyntheticEvent<Element, Event>,
    newVal: any
  ) => {
    setValue(newVal);
    setPage(0);
  };

  useEffect(() => {
    setValue(customerRecords[0]);
  }, [customerData, customerRecords]);

  return (
    <Box>
      <VegaHeaderV4 title="Transactions" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={3}>
        <VegaCardV4>
          <VegaAutocompleteSearchV4
            textFieldProps={TextFieldProps}
            options={customerRecords}
            value={value?.label || ''}
            onChange={updateSearchValue}
          />
          <Stack direction="row" alignItems="center" gap={2}>
            <VegaButtonV4
              variant="outlined"
              onClick={updateDebitTransactionDrawer(true)}
              disabled={!Boolean(customerData)}
            >
              Create Debit Transaction
            </VegaButtonV4>
            <VegaButtonV4
              variant="outlined"
              onClick={updateRepaymentDrawer(true)}
              disabled={!Boolean(customerData)}
            >
              Create Repayment
            </VegaButtonV4>
          </Stack>
        </VegaCardV4>
        {!value || (!records?.length && !isFetching) ? (
          <VegaNoResults
            errorText={
              value
                ? 'No Transaction Found for the customer. Please select another customer'
                : 'No Transaction Found. Please select the customer'
            }
          />
        ) : (
          <>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <VegaSearchfieldV4 />
            </Stack>
            <VegaTableV4
              head={[
                'Txn Id',
                'Txn Time',
                'Amount',
                'Currency',
                'Indicator',
                'Status',
                'Merchant Name',
                'Category',
                'Code',
                'Txn Details',
              ]}
              data={getTransactionAccListStructuredData(
                records,
                transactionDetailsDialogOpen
              )}
              isLoading={isFetching}
              pagination={{
                rowsPerPage: pageSize,
                setRowsPerPage: setPageSize,
                setPage,
                page,
                totalRecords: transactionData?.totalItems || 0,
              }}
            />
          </>
        )}
      </Stack>
      <TransactionDebitDrawer
        open={openDebitTransactionDrawer}
        onClose={updateDebitTransactionDrawer(false)}
        accountId={value?.id}
        refetch={refetch}
      />
      <TransactionRepaymentDrawer
        open={openRepaymentDrawer}
        onClose={updateRepaymentDrawer(false)}
        accountId={value?.id}
        refetch={refetch}
      />
      <VegaDetailsDialogV4
        data={getTransactionDetailsDialogData(selectedTransaction)}
        dialogProps={{
          title: 'Tranasction Details',
          open: openTransactionDetailsDialog,
          onClose: transactionDetailsDialogClose,
        }}
        keyMinWidth={170}
        dialogMinWidth={600}
      />
      <VegaDrawerV4 />
    </Box>
  );
};

const TextFieldProps = {
  header: 'customer',
  standardWidth: 300,
  InputProps: { endAdornment: <SearchIcon /> },
  headerTypo: ListGroupHeadingTypo,
};

export default TransactionListPage;
