import axios, { AxiosResponse } from 'axios';
import { BASE_URL, CLIENT } from './endpoints';
import { IFinanceInvoiceData } from '../types/financeCenter';

const CLIENT_BASE_URL = BASE_URL + CLIENT.BASE;

export const fetchInvoice = (
  clientId: any,
  year?: number | string,
  month?: number
): Promise<AxiosResponse<IFinanceInvoiceData[]>> => {
  return axios.get(`${CLIENT_BASE_URL}/invoice/${clientId}`, {
    params: {
      year,
      month,
    },
  });
};
