/** @format */

import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { VegaProgramType } from '../../types/program';
import VegaSelectSidebar from '../common/VegaSelectSidebar';
import _ from 'lodash';
import VegaSelect from '../common/VegaSelect';
import { DashboardType } from '../layouts/SidebarLayout';

type Props = {
  disabledOn: boolean;
  maxWidth: number;
};

const ProgramSelector = ({ disabledOn, maxWidth }: Props) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const currentView = searchParams.get('view');
  const { selectedProgram, setSelectedProgram, programs, allowedPrograms } =
    useProgramData();

  useEffect(() => {
    if (!!programs && !selectedProgram) {
      setSelectedProgram(programs[0]);
    }
  }, [programs, selectedProgram, setSelectedProgram]);

  if (
    !programs ||
    programs.length === 0 ||
    !selectedProgram ||
    !disabledOn ||
    currentView === ''
  ) {
    return <></>;
  }

  const programOptions = allowedPrograms?.map((program) => ({
    id: program.programId,
    name: program.name,
  }));
  const selectedOption = {
    id: selectedProgram?.programId as string,
    name: selectedProgram?.name as string,
  };

  const handleSelectedProgramChange = (selected: string) => {
    const newSelection = allowedPrograms.filter(
      (program) => program.programId === selected
    )?.[0];
    if (newSelection) {
      setSelectedProgram(newSelection as VegaProgramType);
    }
  };

  return (
    <Box paddingBottom={2}>
      {pathname === DashboardType.BACK_OFFICE ? (
        <Box sx={{ width: '300px' }}>
          <VegaSelect
            selected={selectedOption}
            options={programOptions}
            textFieldWidth={300}
            onChange={(selected: any) => handleSelectedProgramChange(selected)}
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '9px',
              color: 'primary',
            }}
          />
        </Box>
      ) : (
        <VegaSelectSidebar
          label="Program"
          textFieldWidth={maxWidth}
          options={programOptions}
          selected={selectedOption}
          onChange={(selected: any) => handleSelectedProgramChange(selected)}
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '9px',
            color: 'primary',
          }}
          darkLabel
        />
      )}
    </Box>
  );
};

export default ProgramSelector;
