import { useQuery } from 'react-query';
import { fetchUserForClient } from '../../../../api/roles';

export const useListUsers = (request: { clientId: string }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users', request.clientId],
    queryFn: async () => await fetchUserForClient(request.clientId),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  return { ...data, ...rest };
};
