export interface IBillResponseData {
  id: string;
  accountId: string;
  billStartDate: string;
  billEndDate: string;
  billDate: string;
  billDueDate: string;
  billPaidDate: string;
  billPdfLink: string;
  loanId: string;
  loanConversionDate: string;
  createdAt: string;
  updatedAt: string;
  previousBillId: string;
  status: BillStatus;
  billAmount: number;
  debitTxnCount: number;
  debitAmount: number;
  creditTxnCount: number;
  creditAmount: number;
  dueAmount: number;
  dueCharge: number;
  dueInterest: number;
  dueRetail: number;
  dueCash: number;
  dueEmi: number;
  dueOverlimit: number;
  mad: number;
  paidAmount: number;
  paidCharge: number;
  paidInterest: number;
  paidPrincipal: number;
  previousPendingBillAmount: number;
}

export enum BillStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}
