import { Box } from '@mui/material';
import { QUICK_ACCESS } from '../../constants/style';
import PartnerManagementHeader from './PartnerManagementHeader';
import PartnerManagementBrandList from './PartnerManagementBrandList';

const PartnerManagementBrandView = () => {
  return (
    <Box>
      <Box
        sx={{
          padding: '40px 26px 26px',
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
        }}
      >
        <PartnerManagementHeader />
      </Box>
      <Box p={3}>
        <PartnerManagementBrandList />
      </Box>
    </Box>
  );
};

export default PartnerManagementBrandView;
