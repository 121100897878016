import Box from '@mui/material/Box';
import { useMemo } from 'react';
import {
  TransactionCode,
  TransactionLimit,
  TransactionPolicy,
} from '../../../types/policy';
import type { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';

const getTransactionMode = (code: TransactionCode) => {
  switch (code) {
    case TransactionCode.E_COM:
      return 'Card E-com';
    case TransactionCode.POS:
      return 'Card Pos';
    case TransactionCode.CARD_CASH_WITHDRAW:
      return 'Card Cash Withdraw';
    case TransactionCode.CARD_NFC:
      return 'Card NFC';
    default:
      return '-';
  }
};

const priceValueFormatter = ({ value }: GridValueFormatterParams<any>) =>
  `₹ ${(value || 0).toLocaleString('en-US')}`;

const getTTransactionLimitPolicyColumns =
  (): GridColDef<TransactionLimit>[] => [
    {
      field: 'transaction_code',
      headerName: 'Mode',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: ({ value }) => getTransactionMode(value),
    },
    {
      field: 'per_transaction_limit',
      headerName: 'Transaction Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: priceValueFormatter,
    },
    {
      field: 'daily_limit',
      headerName: 'Daily Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: priceValueFormatter,
    },
    {
      field: 'monthly_limit',
      headerName: 'Monthly Limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: priceValueFormatter,
    },
    {
      field: 'yearly_limit',
      headerName: 'Annual limit',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: priceValueFormatter,
    },
  ];

type ViewTransactionLimitPolicyProps = {
  policy: TransactionPolicy;
};

function ViewTransactionLimitPolicy({
  policy,
}: ViewTransactionLimitPolicyProps) {
  const columnsRef = useMemo(() => getTTransactionLimitPolicyColumns(), []);

  return (
    <Box
      sx={{
        py: 3,

        flex: 1,
      }}
    >
      <ProgramDataGrid
        data={policy?.transaction_limits ?? []}
        columns={columnsRef}
        idColumn="transaction_code"
      />
    </Box>
  );
}

export default ViewTransactionLimitPolicy;
