import React, { useMemo, useState } from 'react';
import VegaHeaderV4 from '../common/v4/VegaHeaderV4';
import { Box } from '@mui/material';
import VegaTabV4, { ITabData } from '../common/v4/VegaTabV4';
import InstitutionStandardGazettedHolidays from './InstitutionStandardGazettedHolidays';
import InstitutionStandardSundayHolidays from './InstitutionStandardSundayHolidays';
import InstitutionStandardSaturdayHolidays from './InstitutionStandardSaturdayHolidays';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InstitutionStandardAddHolidayForm from './InstitutionStandardAddHolidayForm';
import VegaDialogV4 from '../common/v4/VegaDialogV4';
import { SectionHeadingTypo } from '../common/Typography';

type HolidayTabType = 'gazetted' | 'sundays' | 'saturdays';

const InstitutionStandardHolidays = () => {
  const [tab, setTab] = useState<HolidayTabType>('gazetted');
  const [search, setSearch] = useState<string>('');
  const [openAddHolidayDrawer, setOpenAddHolidayDrawer] = useState(false);
  const [openDeleteHolModal, setOpenDeleteHolModal] = useState(false);

  const onTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: HolidayTabType
  ) => {
    setTab(val);
  };

  const searchUpdate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };

  const updateDrawer = (val: boolean) => () => {
    setOpenAddHolidayDrawer(val);
  };

  const updateDialog = (val: boolean) => () => {
    setOpenDeleteHolModal(val);
  };

  const tabData = useMemo(
    (): ITabData[] => [
      {
        value: 'gazetted',
        label: 'Gazetted Holidays',
        children: (
          <InstitutionStandardGazettedHolidays
            openDialog={updateDialog(true)}
            openDrawer={updateDrawer(true)}
          />
        ),
      },
      {
        value: 'sundays',
        label: 'Sundays',
        children: <InstitutionStandardSundayHolidays />,
      },
      {
        value: 'saturdays',
        label: 'Second and Fourth Saturdays',
        children: <InstitutionStandardSaturdayHolidays />,
      },
    ],
    []
  );

  return (
    <Box>
      <VegaHeaderV4
        title="Institutions Standards"
        actionBtnProps={{
          value: 'Add Bank Holiday',
          startIcon: <AddCircleOutlineOutlinedIcon fontSize="small" />,
          variant: 'contained',
          onClick: updateDrawer(true),
        }}
        sx={{ pt: 5 }}
      />
      <Box p={3}>
        <VegaTabV4
          tabData={tabData}
          value={tab}
          onChange={onTabChange}
          searchInfo={{
            onChange: searchUpdate,
            value: search,
          }}
        />
      </Box>
      <InstitutionStandardAddHolidayForm
        open={openAddHolidayDrawer}
        setOpen={updateDrawer}
      />
      <VegaDialogV4
        open={openDeleteHolModal}
        onClose={updateDialog(false)}
        title="Delete Holiday"
        actionBtnPrimaryText="No, thanks"
        actionBtnPrimaryProps={{
          onClick: updateDialog(false),
        }}
        actionBtnSecondaryText="Yes"
        actionBtnSecondaryProps={{
          onClick: updateDialog(false),
        }}
      >
        <Box py={4} px={5}>
          <SectionHeadingTypo>
            Are you sure you want to delete Holiday ?
          </SectionHeadingTypo>
        </Box>
      </VegaDialogV4>
    </Box>
  );
};

export default InstitutionStandardHolidays;
