import { Box, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import PartnerEntityDetailsForm from './PartnerEntityDetailsForm';
import PartnerAdminDetailsForm from './PartnerAdminDetailsForm';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import PartnerService from '../../services/PartnerService';
import { EntityStatusEnum } from '../../enums/commons';
import { ICreatePartnerRequest, IPartner } from '../../types/partner';
import { usePartnersList } from '../../store/queries/partnerQueries';
import {
  IAddUserRequest,
  UserPlatformEnums,
  UserTeamType,
  addRole,
  addUser,
} from '../../mcc/Program-Dashboard/api/roles';
import { getAccessFeaturesForPartner } from '../../mcc/Program-Dashboard/components/PartnerMangement/utils/addUserForPartner';
import { addRoleForUser } from '../../api/roleManagement';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import { useState } from 'react';
import VegaHeaderV4 from '../common/v4/VegaHeaderV4';
import { useUpdatePartner } from '../../mcc/Program-Dashboard/components/PartnerMangement/hooks/partner/mutations';

export interface IPartnerManagementAddBrandFormValues {
  entityDetails: ICreatePartnerRequest;
  adminDetails: IAddUserRequest;
}

interface IPartnerManagementAddBrandFormProps {
  isEdit?: boolean;
}

const PartnerManagementAddBrandForm = ({
  isEdit,
}: IPartnerManagementAddBrandFormProps) => {
  const { setCurrState, partner } = usePartnerMgmtContext();
  const partnerMutation = useUpdatePartner();
  const partnerFormHook = useForm<IPartnerManagementAddBrandFormValues>({
    mode: 'onTouched',
    defaultValues: isEdit
      ? {
          entityDetails: {
            name: partner?.name,
            panNo: partner?.panNo,
            gstNo: partner?.gstNo,
            cinNo: partner?.cinNo,
            companyType: partner?.companyType,
            ...partner?.address,
          },
        }
      : undefined,
  });
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = partnerFormHook;

  const clearForm = () => {
    reset();
  };

  const moveBack2PartnerMgmtMainPage = () => {
    setCurrState?.('brand');
  };

  const partnersQuery = usePartnersList({
    clientId: clientId || '',
    page: 0,
    pageSize: 50,
  });

  const submitForm = () => {
    setIsLoading(true);
    const data = getValues();

    if (isEdit) {
      if (!partner?.id) return;
      const entity = data.entityDetails;
      partnerMutation.mutate(
        {
          id: partner.id,
          name: entity.name,
          panNo: entity.panNo,
          gstNo: entity.gstNo,
          cinNo: entity.cinNo,
          companyType: entity.companyType,
          partnerType: entity.partnerType,
          line1: entity.line1,
          line2: entity.line1,
          pincode: entity.pincode,
          city: entity.city,
          state: entity.state,
          country: entity.country,
          logos: entity.logo ? [entity.logo] : null,
          agreement: entity.agreement,
        },
        {
          onSuccess: (res) => {
            setSnackbar('Partner Updated Successfully');
          },
          onError: (err) => {
            setSnackbar('Error in updating the partner', 'error');
          },
          onSettled: (res) => {
            setCurrState?.('brand');
            setIsLoading(false);
          },
        }
      );
      return;
    }

    PartnerService.createPartner({
      ...data.entityDetails,
      logos: data.entityDetails.logo ? [data.entityDetails.logo] : null,
      clientId: clientId || '',
      status: EntityStatusEnum.ACTIVE,
    })
      .then((res: IPartner) => {
        partnersQuery.refetch();
        setSnackbar('Successfully created partner');

        const { designation, ...user } = data.adminDetails;
        const userPromise = addUser({
          ...user,
          clientId: clientId || '',
          platformId: res.id,
          platformName: UserPlatformEnums.FOREX_HQ,
          mccUser: true,
          idType: 'MOBILE_NO',
          idNumber: user.mobileNumber,
          status: 'ACTIVE',
          team: UserTeamType.OPERATIONS,
        });

        const rolePromise = addRole({
          clientId,
          roleName: res.id + '_ADMIN',
          createdBy: user?.name || '',
          status: 'ACTIVE',
          accessFeatures: getAccessFeaturesForPartner(res.id),
        });

        Promise.all([userPromise, rolePromise]).then((res) => {
          const user = res[0].data;
          const role = res[1].data;

          const roleForUser = {
            userId: user.userId,
            roleId: role.id,
            roleName: role?.roleName,
            checkerMakerFlag: true,
          };
          addRoleForUser(roleForUser)
            .then(() => {
              setSnackbar('Success fully created user');
            })
            .catch((err) => {
              console.log('🚀 ~ file: PartnerManagement.tsx:236 ~ err:', err);
              setSnackbar('Error in adding user and role', 'error');
            })
            .finally(() => {
              setCurrState?.('brand');
              setIsLoading(false);
            });
        });
      })
      .catch((err) => {
        console.log('🚀 ~ file: PartnerManagement.tsx:243 ~ err:', err);
        setSnackbar('Error in adding partner', 'error');
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <VegaHeaderV4
        breadcrumbs={[
          { label: 'Partner Management', link: moveBack2PartnerMgmtMainPage },
          { label: isEdit ? 'Edit Partner Details' : 'Add Partner' },
        ]}
        title={isEdit ? 'Edit Partner Details' : 'Add Partner'}
        onBackArrowClick={moveBack2PartnerMgmtMainPage}
      />
      <Box p={3}>
        <PartnerEntityDetailsForm partnerFormHook={partnerFormHook} />
        {!isEdit && (
          <PartnerAdminDetailsForm partnerFormHook={partnerFormHook} />
        )}
        <Stack direction="row" justifyContent="flex-end" mt={5} gap={2}>
          <VegaButtonV4 onClick={clearForm} disabled={!isDirty || isLoading}>
            Clear
          </VegaButtonV4>
          <VegaButtonV4
            variant="contained"
            onClick={handleSubmit(submitForm)}
            disabled={!isDirty || isLoading}
            loading={isLoading}
          >
            {`${isEdit ? 'Update Partner Details' : 'Add Partner'}`}
          </VegaButtonV4>
        </Stack>
      </Box>
    </Box>
  );
};

export default PartnerManagementAddBrandForm;
