import { AddCircleOutline, ChevronRight } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../../../components/common';
import VegaModal from '../../../../../components/common/VegaModal';
import { EditIcon } from '../../../../../components/icon/Icons';
import { BACKGROUND } from '../../../../../constants/style';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { IPartner } from '../../../../../types/partner';
import {
  convertCamelCase,
  getInitials,
} from '../../../../../utils/stringUtils';
import { IAddUserRequest } from '../../../api/roles';
import AddPartner from '../AddPartner';
import CreatePartner from '../CreatePartner';
import { useUpdatePartner } from '../hooks/partner/mutations';
import { usePrograms } from '../hooks/program/queries';
import ProgramTeamTab from './ProgramTeamTab';

type IProps = {
  selectedPartener: IPartner | null;
  setSelectedPartner: React.Dispatch<React.SetStateAction<IPartner | null>>;
  setIsCreatePartner: React.Dispatch<React.SetStateAction<boolean>>;
};

function PartnerPrograms({
  selectedPartener,
  setSelectedPartner,
  setIsCreatePartner,
}: IProps) {
  const { clientId } = useClientAuth();
  const [isOpen, setIsOpen] = useState(false);
  const partnerMutation = useUpdatePartner();
  const { setSnackbar } = useSnackbar();
  const programQueries = usePrograms({
    clientId: clientId || '',
    // clientId: '6c26d34b-fe1a-446f-9668-6b7cb5515ef1',
    page: 0,
    pageSize: 10,
  });

  const activePrograms = useMemo(() => {
    return programQueries.data?.records.length
      ? programQueries.data?.records.reduce((pre, curr) => {
          const isActive =
            curr?.status === 'PRODUCTION' || curr?.status === 'ACTIVE';
          return pre + (isActive ? 1 : 0);
        }, 0)
      : 0;
  }, [programQueries.data?.records]);

  const onSubmit = (data: { partner: IPartner; user: IAddUserRequest }) => {
    partnerMutation.mutate(
      {
        id: data.partner.id,
        name: data.partner.name,
        email: data.partner.email,
        mobileNo: data.partner.mobileNo,
        gstNo: data.partner.gstNo,
        cinNo: data.partner.cinNo,
      } as any,
      {
        onSuccess: (res) => {
          setSnackbar('Partner Update Successfully ');
        },
        onError: (err) => {
          setSnackbar('Error in updating the partner', 'error');
        },
        onSettled: (res) => {
          setIsOpen(false);
        },
      }
    );
  };
  return (
    <>
      <VegaPage>
        <VegaCard
          sx={{
            overflow: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for Chrome, Safari, and Opera
            },
            '-ms-overflow-style': 'none', // Hide the scrollbar for Internet Explorer and Edge
          }}
        >
          <Stack spacing={1.3}>
            <Divider />
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography
                variant="body2"
                sx={{
                  ':hover': selectedPartener && {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => setSelectedPartner(null)}
              >
                Co-brand Partner
              </Typography>
              <ChevronRight color="warning" />

              <Typography variant="body2" fontWeight={'bold'}>
                {convertCamelCase(selectedPartener?.name || '')}
              </Typography>
            </Stack>
            <Divider />
          </Stack>
          {/* ---------------------------------------PARTNER BRIEF--------------------------------------- */}
          <Stack direction={'row'} py={2} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={4}>
              <Stack spacing={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  PARTNER
                </Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Avatar
                    sx={{
                      bgcolor: BACKGROUND.GERY,
                      border: '1.5px solid',
                      width: 30,
                      height: 30,
                      fontSize: 15,
                    }}
                  >
                    {getInitials(selectedPartener?.name || '')}
                  </Avatar>
                  <Typography>{selectedPartener?.name}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  COMPANY TYPE
                </Typography>
                <Stack>
                  <Typography>{selectedPartener?.companyType}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  PAN NO.
                </Typography>
                <Stack>
                  <Typography>{selectedPartener?.panNo}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  PROGRAMS LIVE
                </Typography>
                <Stack direction={'row'} spacing={1}>
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{
                      color: '#11A75C',
                    }}
                  />
                  <Typography>
                    {activePrograms +
                      ' / ' +
                      (programQueries.data?.records?.length || 0)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box>
              <Button
                startIcon={<EditIcon color="#001AA1" width={20} height={20} />}
                color="primary"
                onClick={() => setIsOpen(true)}
              >
                Edit
              </Button>
            </Box>
          </Stack>
        </VegaCard>

        {/*--------------------------------------- PROGRMS TEAM TAB--------------------------------------- */}
        <ProgramTeamTab partner={selectedPartener} />
      </VegaPage>

      <VegaModal
        header={'Update Partner'}
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        isButton={false}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
        zIndex={2500}
      >
        <AddPartner
          components={[
            <CreatePartner
              initialValue={
                { ...selectedPartener, ...selectedPartener?.address } as any
              }
            />,
          ]}
          onSubmit={onSubmit}
          onCancel={() => {
            setIsOpen(false);
          }}
          steps={['Add Team Member']}
        />
      </VegaModal>
    </>
  );
}

export default PartnerPrograms;
