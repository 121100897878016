import { Box, Stack } from '@mui/material';
import { GENERIC_COLORS, QUICK_ACCESS } from '../../../constants/style';
import { GenericTypo1, PageHeadingTypo } from '../../common/Typography';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaTabV4, { ITabData } from '../../common/v4/VegaTabV4';
import { useMemo, useState } from 'react';
import SimulatorHomeCardContainer from './SimulatorHomeCardContainer';
import { useClientAuth } from '../../../providers/ClientProvider';
import ProfileDisplay from '../../common/v4/ProfileDisplay';
import { VegaProgramCategoryType } from '../../../types/program';

const SimulatorHome = () => {
  const [tab, setTab] = useState<VegaProgramCategoryType | 'all'>('all');
  const [search, setSearch] = useState('');
  const { client } = useClientAuth();

  const tabData = useMemo(
    (): ITabData[] => [
      {
        value: 'all',
        label: 'All',
        children: <SimulatorHomeCardContainer category="all" search={search} />,
      },
      {
        value: VegaProgramCategoryType.CREDIT_CARD,
        label: 'Credit Cards',
        children: (
          <SimulatorHomeCardContainer
            category={VegaProgramCategoryType.CREDIT_CARD}
            search={search}
          />
        ),
      },
      {
        value: VegaProgramCategoryType.PREPAID_CARD,
        label: 'Debit Cards',
        children: (
          <SimulatorHomeCardContainer
            category={VegaProgramCategoryType.PREPAID_CARD}
            search={search}
          />
        ),
      },
      {
        value: VegaProgramCategoryType.FOREX_CARD,
        label: 'Multicurrency Cards',
        children: (
          <SimulatorHomeCardContainer
            category={VegaProgramCategoryType.FOREX_CARD}
            search={search}
          />
        ),
      },
      {
        value: VegaProgramCategoryType.LOANS,
        label: 'Loans',
        children: (
          <SimulatorHomeCardContainer
            category={VegaProgramCategoryType.LOANS}
            search={search}
          />
        ),
      },
    ],
    [search]
  );

  const onTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: VegaProgramCategoryType
  ) => {
    setTab(val);
  };

  const onSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };

  return (
    <Stack height={'100vh'}>
      <Stack
        direction="row"
        height={160}
        justifyContent="space-between"
        sx={{
          background: GENERIC_COLORS.LINEAR_GRADIANT.blue1,
          color: QUICK_ACCESS.text[50],
        }}
      >
        <Stack justifyContent="flex-end" py={3.5} px={10}>
          <GenericTypo1>
            Welcome back{client?.clientName ? `, ${client?.clientName}` : ''}!
          </GenericTypo1>
        </Stack>
        <Stack py={1.5} px={3}>
          <ProfileDisplay />
        </Stack>
      </Stack>
      <Stack py={5} px={10} gap={3.5} flex={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <PageHeadingTypo>Access the Vegapay’s Simulator!</PageHeadingTypo>
          <VegaSearchfieldV4
            placeholder="Search by Name/Code/ID"
            standardWidth={412}
            value={search}
            onChange={onSearchChange}
          />
        </Stack>
        <Stack gap={2} flex={1}>
          <VegaTabV4
            tabData={tabData}
            value={tab}
            onChange={onTabChange}
            tabPanelProps={{ sx: { flex: 1 } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SimulatorHome;
