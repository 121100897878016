import { API_BASE_URL } from '../../../api/axiosConfig';

export const BASE_URL = API_BASE_URL;
// export const BASE_URL =
//   'http://ec2-13-235-248-72.ap-south-1.compute.amazonaws.com:8510';
export const GETWAY = '/forex';

export const CREATE_CUSTOMER = {
  // BASE: '/customer/retail',
  BASE: '/customer',
};
export const CUSTOMER = {
  BASE: '/customer',
};

export const CORPORATE = {
  CUSTOMER: '/customer/corporate',
  ACCOUNT: '/account/corporate',
};
export const TRANSACTION = {
  BASE: '/transaction',
  CORPORATE: '/transaction/corporate',
};
export const ACCOUNT = {
  BASE: '/account',
  PLAN: '/plan',
  SEARCH: '/account/search',
  FILTER: '/account/filter',
  CONSOLIDATED_FILTER: '/account/consolidated/filter',
};

export const BIN = {
  BASE: '/bin',
};
export const RATE_MANAGER = {
  CURRENCY_CONVERT: '/currencyConversionRate',
};
export const OPEN_CREDIT = {
  BASE: '/open_credit',
};

export const REPORTING = {
  BASE: '/reporting',
};
