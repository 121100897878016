import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { listTransactionsForAccount } from '../../api/transaction';
import { getLedgerData } from '../../api/ledger';
import { getBillsForAccount } from '../../api/bill';

export const useTransactionAccountListQuery = ({
  accountId,
  page,
  pageSize,
}: {
  accountId: string;
  page?: number;
  pageSize?: number;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.TRANSACTION_LIST_ACCOUNT, accountId, page, pageSize],
    queryFn: () => listTransactionsForAccount(accountId, page, pageSize),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useLedgerListQuery = ({
  accountId,
  page,
  pageSize,
  enabled,
}: {
  accountId: string;
  page?: number;
  pageSize?: number;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.LEDGER_LIST, accountId, page, pageSize],
    queryFn: () => getLedgerData({ accountId, page, pageSize }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useBillListQuery = ({
  accountId,
  page,
  pageSize,
}: {
  accountId: string;
  page?: number;
  pageSize?: number;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.BILL_LIST, accountId, page, pageSize],
    queryFn: () => getBillsForAccount(accountId, page, pageSize),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};
