import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { fetchAuditrByCustomerId, listForCustomers } from '../../api/customer';

export const useCustomerListQuery = ({
  clientId,
  programId,
  page,
  pageSize,
  allAccounts,
}: {
  programId: string;
  clientId: string;
  page?: any;
  pageSize?: any;
  allAccounts?: boolean;
}) => {
  const res = useQuery({
    queryKey: [
      QUERY_NAMES.CUSTOMER_ACCOUNT_LIST,
      programId,
      clientId,
      page,
      pageSize,
      allAccounts,
    ],
    queryFn: () =>
      listForCustomers(programId, clientId, page, pageSize, allAccounts),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(programId && clientId),
  });

  return res;
};

export const useCustomerAuditQuery = (customerId: string) => {
  const res = useQuery({
    queryKey: [QUERY_NAMES.CUSTOMER_AUDIT, customerId],
    queryFn: () => fetchAuditrByCustomerId(customerId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(customerId),
  });

  return res;
};
