import { KeyboardArrowDown } from '@mui/icons-material';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack, { StackProps } from '@mui/material/Stack';
import {
  INDICATIVE,
  NEUTRALS,
  PRIMARY_COLOR,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../constants/style';
import {
  ComponentBody2Typo,
  LabelTypo,
  ListHead2Typo,
  TextProps,
} from '../Typography';
import Box from '@mui/material/Box';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { CSSProperties } from 'react';

export type VegaSelectOptionType = {
  id: string | number;
  name: string | number;
  icon?: JSX.Element;
};

interface VegaSelectV4Props extends SelectProps<string | number> {
  options: VegaSelectOptionType[];
  stroked?: boolean;
  header?: string;
  labelText?: string;
  wrapperProps?: StackProps;
  isRequired?: boolean;
  headerTypo?: (props: TextProps) => JSX.Element;
  standardWidth?: CSSProperties['width'];
}

const VegaSelectV4 = ({
  options,
  stroked = true,
  header,
  labelText,
  wrapperProps,
  isRequired,
  headerTypo,
  multiple,
  onChange,
  sx,
  MenuProps,
  standardWidth,
  ...rest
}: VegaSelectV4Props) => {
  const HEADER_TYPO = headerTypo || ListHead2Typo;
  const { sx: wrapperSx, ...wrapperRest } = wrapperProps || {};

  return (
    <>
      <Stack
        gap={1}
        sx={{ width: standardWidth || 328, ...wrapperSx }}
        {...wrapperRest}
      >
        {header ? (
          <Box
            sx={{
              display: 'inline-flex',
              gap: 0.5,
            }}
          >
            <HEADER_TYPO
              sx={{
                color: QUICK_ACCESS.text[100],
                display: 'inline-block',
              }}
            >
              {header}
            </HEADER_TYPO>{' '}
            {rest.required || isRequired ? (
              <HEADER_TYPO
                sx={{
                  color: INDICATIVE.red[500],
                  display: 'inline-block',
                }}
              >
                *
              </HEADER_TYPO>
            ) : null}
          </Box>
        ) : null}
        <Select
          fullWidth
          displayEmpty
          IconComponent={KeyboardArrowDown}
          sx={{
            width: standardWidth || 328,
            borderRadius: '4px',
            color: VEGA_COMMON.text200,
            height: multiple ? 'auto' : '35px',
            backgroundColor: VEGA_COMMON.background[50],

            ':hover': {
              bgcolor: NEUTRALS.white[100],
            },
            '&.MuiOutlinedInput-root.Mui-disabled': {
              bgcolor: NEUTRALS.white[100],
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: stroked ? 1 : 0,
              borderColor: VEGA_COMMON.border[50],
            },
            '& .MuiSvgIcon-root': {
              width: '1rem',
              height: '1rem',
              right: '0.625rem',
              top: '0.5625rem',
              fill: NEUTRALS.grey[200],
            },
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center: ',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: stroked ? 1 : 0,
              borderColor: VEGA_COMMON.border[50],
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: stroked ? 1 : 0,
              borderColor: VEGA_COMMON.border[50],
            },
            ...sx,
          }}
          MenuProps={{
            sx: {
              '& .MuiMenu-paper': {
                backgroundColor: VEGA_COMMON.background[50],
                boxShadow: 'none',
                borderWidth: 1,
                borderColor: VEGA_COMMON.border[50],
                borderStyle: 'solid',
                maxHeight: '350px',
              },
              '& .MuiMenu-list': {
                py: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              },
              '& .MuiMenu-list .Mui-selected': {
                bgcolor: NEUTRALS.white[100],
              },
            },
            ...MenuProps,
          }}
          renderValue={(selected: string | number | string[] | number[]) => {
            let selectedValue:
              | VegaSelectOptionType
              | VegaSelectOptionType[]
              | undefined;
            if (multiple) {
              selectedValue = options?.filter((option) =>
                typeof option === 'string' || typeof option === 'number'
                  ? (selected as (string | number)[])?.findIndex(
                      (s) => s === option
                    ) > -1
                  : (selected as (string | number)[])?.findIndex(
                      (s) => s === option.id
                    ) > -1
              );
            } else {
              selectedValue = options?.find((option) =>
                typeof option === 'string' || typeof option === 'number'
                  ? option === selected
                  : option.id === selected
              );
            }
            return Array.isArray(selectedValue) ? (
              <Stack
                justifyContent="flex-start"
                direction="row"
                alignItems="center"
                gap={1}
                flexWrap={multiple ? 'wrap' : undefined}
              >
                {selectedValue.length ? (
                  selectedValue.map((val, idx) => (
                    <Stack
                      key={val.id}
                      sx={{
                        backgroundColor: PRIMARY_COLOR.blue[50],
                        py: 0.5,
                        px: 1,
                        borderRadius: '4px',
                      }}
                      direction="row"
                      gap={1}
                    >
                      <ComponentBody2Typo
                        sx={{
                          color: VEGA_COMMON.text200,
                        }}
                      >
                        {val?.name || rest.placeholder || ''}
                      </ComponentBody2Typo>
                      {/* <CloseIcon
                        fontSize="small"
                        style={{
                          fill: QUICK_ACCESS.text[200],
                        }}
                        onClick={() => onUnSelect(val?.id)}
                      /> */}
                    </Stack>
                  ))
                ) : (
                  <ComponentBody2Typo
                    sx={{
                      color: VEGA_COMMON.text75,
                    }}
                  >
                    {rest.placeholder || ''}
                  </ComponentBody2Typo>
                )}
              </Stack>
            ) : (
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                gap={1}
              >
                {selectedValue?.icon && selectedValue.icon}
                <ComponentBody2Typo
                  sx={{
                    color: selectedValue
                      ? VEGA_COMMON.text200
                      : VEGA_COMMON.text75,
                  }}
                >
                  {selectedValue?.name || rest.placeholder || ''}
                </ComponentBody2Typo>
              </Stack>
            );
          }}
          {...rest}
          multiple={multiple}
          onChange={onChange}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Stack
                    direction={'row'}
                    gap={2}
                    alignItems="center"
                    fontWeight={400}
                    fontSize={12}
                    color={VEGA_COMMON.text200}
                  >
                    {option.icon}
                    {option.name}
                  </Stack>
                  {((multiple &&
                    (rest.value as any)?.indexOf(option.id) > -1) ||
                    (!multiple && rest.value === option.id)) && (
                    <DoneRoundedIcon fontSize="small" />
                  )}
                </Stack>
              </MenuItem>
            ))}
        </Select>
        {labelText && (
          <LabelTypo
            sx={{
              color: rest.error ? INDICATIVE.red[500] : VEGA_COMMON.text100,
            }}
          >
            {labelText}
          </LabelTypo>
        )}
      </Stack>
    </>
  );
};

export default VegaSelectV4;
