import React, { PropsWithChildren } from 'react';
import { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { VEGA_COMMON } from '../../../constants/style';
import { Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel, TabPanelProps } from '@mui/lab';
import VegaSearchfieldV4 from './VegaSearchfieldV4';

const StyledTabs = styled(TabList)({
  minHeight: 24,
  '& .MuiTabs-indicator': {
    backgroundColor: VEGA_COMMON.primaryBlue,
  },
  '& .MuiTabs-flexContainer': {
    gap: '16px',
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  padding: '0 4px',
  fontWeight: 500,
  color: VEGA_COMMON.text100,
  fontFamily: 'Aspekta-500 !important',
  fontSize: 12,
  minHeight: 24,
  '&:hover': {
    color: VEGA_COMMON.primaryBlue,
    opacity: 0.8,
  },
  '&.Mui-selected': {
    color: VEGA_COMMON.primaryBlue,
  },
}));

export interface ITabData extends PropsWithChildren {
  value: string;
  label: string;
}

type VegaTabV4Props = {
  tabData: ITabData[];
  searchInfo?: {
    value: string;
    onChange: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
  };
  tabPanelProps?: Omit<TabPanelProps, 'value'>;
} & TabsProps;

function VegaTabV4({
  tabData,
  value,
  onChange,
  searchInfo,
  tabPanelProps,
}: VegaTabV4Props) {
  return (
    <TabContext value={value}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <StyledTabs onChange={onChange}>
          {tabData.map((item) => (
            <StyledTab
              value={item.value}
              label={item.label}
              key={item.value}
              disableRipple
            />
          ))}
        </StyledTabs>
        {searchInfo && (
          <VegaSearchfieldV4
            value={searchInfo.value}
            onChange={searchInfo.onChange}
          />
        )}
      </Stack>
      {tabData.map((item) => {
        if (!item.children) return null;
        return (
          <TabPanel
            {...tabPanelProps}
            value={item.value}
            key={item.value}
            sx={{ p: 0, ...tabPanelProps?.sx }}
          >
            {item.children}
          </TabPanel>
        );
      })}
    </TabContext>
  );
}

export default VegaTabV4;
