import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, SubmitHandler, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import VegaBreadcrumbs from '../common/v2/VegaBreadcrumbs';
import VegaTextV2 from '../common/v2/VegaTextV2';
import ArrowLeftIcon from '../icon/ArrowLeftIcon';
import { PageHeadingTypo } from '../common/Typography';
import { VEGA_COMMON } from '../../constants/style';
import { VegaPage } from '../common';
import VegaWrapperType1 from '../common/VegaWrapperType1';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import {
  VegaLoanProductType,
  VegaLoanType,
  VegaProgramCategoryType,
  VegaProgramIssuanceType,
  VegaProgramNetworkType,
} from '../../types/program';
import VegaSelectV4, { VegaSelectOptionType } from '../common/v4/VegaSelectV4';
import { convertCamelCase } from '../../utils/stringUtils';
import VegaUploadFileInputV4 from '../common/v4/VegaUploadFileInputV4';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { fetchListOfUsersForClient } from '../../api/roleManagement';
import { VegaBin, VegaBinUsageType } from '../../types/client';
import { VegaUser } from '../../types/user';
import { listBinForClient } from '../../api/bin';
import PartnerService from '../../services/PartnerService';
import { EntityStatusEnum } from '../../enums/commons';
import {
  CATEGORIES,
  COMMODITY_LOAN_TYPES,
  CREDIT_CARD_PRODUCTS,
  FOREX_CARD_PRODUCTS,
  ISSUANCE_TYPES,
  LOAN_PRODUCTS,
  PREPAID_CARD_PRODUCTS,
  SECURED_LOAN_TYPES,
  UNSECURED_LOAN_TYPES,
} from '../../constants/program';
import { FileValidatorBuilder, FileExtension } from '../../utils/FileValidator';
import { AcceptFileType } from '../../types/acceptFileType';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import { createNewProgram } from '../../api/program';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { MasterCardIcon, RupayIcon, VisaIcon } from '../icon/Icons';

type AddProgramProps = {
  handleGoBackClicked: () => void;
};

type AddProgramFormInput = {
  programName: string;
  programCode: string;
  productType: VegaProgramCategoryType;
  issuanceType?: VegaProgramIssuanceType;
  maker?: string;
  checker?: string;
  product: string;
  bin?: string; // bin id
  network: string;
  loanType?: VegaLoanType;
  coBrandPartnerName?: string;
  agreement?: File;
  tnc?: File;
};

const dataToOptions = (data: any[]) =>
  Object.values(data).map((item) => {
    const option: VegaSelectOptionType = {
      id: item,
      name: convertCamelCase(item),
    };
    return option;
  });

const getFilteredBinOptions = (data: VegaBin[]): VegaSelectOptionType[] => {
  return data.map((item) => ({
    id: item.id.toString(),
    name: `${item.binValue} ${convertCamelCase(item.productType ?? '')} ${
      item.binName
    }`,
  }));
};

export const NETWORKS: VegaSelectOptionType[] = [
  {
    id: VegaProgramNetworkType.MASTER_CARD,
    name: 'Mastercard',
    icon: <MasterCardIcon />,
  },
  {
    id: VegaProgramNetworkType.RUPAY,
    name: 'Rupay',
    icon: <RupayIcon />,
  },
  {
    id: VegaProgramNetworkType.VISA,
    name: 'Visa',
    icon: <VisaIcon />,
  },
];

const categoriesOptions = dataToOptions(CATEGORIES);
const issuanceTypeOptions = dataToOptions(ISSUANCE_TYPES);

const getProductsForCategory = (category: VegaProgramCategoryType) => {
  switch (category) {
    case VegaProgramCategoryType.CREDIT_CARD:
      return CREDIT_CARD_PRODUCTS;
    case VegaProgramCategoryType.LOANS:
      return LOAN_PRODUCTS;
    case VegaProgramCategoryType.FOREX_CARD:
      return FOREX_CARD_PRODUCTS;
    case VegaProgramCategoryType.PREPAID_CARD:
      return PREPAID_CARD_PRODUCTS;
    default:
      return null;
  }
};
const getSubSectionsForLoan = (category: VegaLoanProductType) => {
  switch (category) {
    case VegaLoanProductType.SECURED:
      return SECURED_LOAN_TYPES;
    case VegaLoanProductType.UNSECURED:
      return UNSECURED_LOAN_TYPES;
    case VegaLoanProductType.COMMODITY_FINANCING:
      return COMMODITY_LOAN_TYPES;
    default:
      return null;
  }
};
const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.PDF])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

function AddProgram({ handleGoBackClicked }: AddProgramProps) {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [teamOptions, setTeamOptions] = useState<VegaSelectOptionType[]>([]);
  const [binList, setBinList] = useState<VegaBin[]>([]);
  const [partnerOptions, setPartnerOptions] = useState<VegaSelectOptionType[]>(
    []
  );
  const [fileUploadValidationErrors, setFileUploadValidationErrors] = useState({
    agreement: '',
    tnc: '',
  });
  const hookForm = useForm<AddProgramFormInput>({});

  const currentProductType = useWatch({
    name: 'productType',
    control: hookForm.control,
  });
  const currentIssuanceType = useWatch({
    name: 'issuanceType',
    control: hookForm.control,
  });
  const currentProduct = useWatch({
    name: 'product',
    control: hookForm.control,
  });

  const currentProducts = useMemo(
    () => getProductsForCategory(currentProductType),
    [currentProductType]
  );

  const currentProductOptions = useMemo(
    () => (currentProducts ? dataToOptions(currentProducts) : null),
    [currentProducts]
  );

  const filteredBinOptions = useMemo(
    () => getFilteredBinOptions(binList),
    [binList]
  );

  const currentLoanSectionOptions = useMemo(() => {
    if (
      currentProductType === VegaProgramCategoryType.LOANS &&
      currentProduct
    ) {
      const subSections = getSubSectionsForLoan(
        currentProduct as VegaLoanProductType
      );
      if (subSections) {
        return dataToOptions(subSections);
      } else {
        return [];
      }
    }

    return [];
  }, [currentProduct, currentProductType]);

  const onSubmit: SubmitHandler<AddProgramFormInput> = (data, event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (!clientId) {
      return;
    }
    setIsLoading(true);
    createNewProgram({
      clientId,
      name: data.programName,
      programCode: data.programCode,
      agreement: data.agreement,
      tnc: data.tnc,
      type: data.product,
      category: data.productType!,
      issuanceType: data.issuanceType!,
      binId: data.bin,
      makerUserId: data.maker,
      checkerUserId: data.checker,
      coBrandPartnerName: data.coBrandPartnerName,
      network: data.network,
    })
      .then((res) => {
        setSnackbar(`Successfully created Program ${data.programName}`);
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    function fetchClientTeams() {
      if (!clientId) {
        return;
      }
      fetchListOfUsersForClient(clientId)
        .then((response) => {
          const team = response.data as VegaUser[];
          const mappedOptions: VegaSelectOptionType[] = team.map((user) => ({
            id: user.userId,
            name: user.name,
          }));
          console.log(team);

          setTeamOptions(mappedOptions);
        })
        .catch((error) => {});
    }
    function fetchBinList() {
      if (!clientId) {
        return;
      }
      listBinForClient(clientId, 0, 100)
        .then((response) => {
          const binList = response.data.records as VegaBin[];
          setBinList(binList);
        })
        .catch((error) => {
          setSnackbar('Error while fetching bin list!', 'error');
        });
    }
    function fetchCoBrandPartners() {
      if (!clientId) {
        return;
      }
      PartnerService.listPartners({
        clientId,
        statusList: [EntityStatusEnum.ACTIVE],
        page: 0,
        pageSize: 100,
      }).then((response) => {
        const coBrandPartnersOptions: VegaSelectOptionType[] =
          response?.records.map((item) => ({
            id: item.name || item.id,
            name: item.name || item.id,
          }));
        setPartnerOptions(coBrandPartnersOptions);
      });
    }
    fetchClientTeams();
    fetchBinList();
    fetchCoBrandPartners();
  }, []);
  return (
    <VegaPage sx={{ gap: 3 }}>
      <Box
        sx={{
          borderBottom: `1px solid ${VEGA_COMMON.border[50]}`,
          px: 3.25,
          pb: 1,
        }}
      >
        <Stack>
          <Stack pt={1.5} pb={0.75}>
            <VegaBreadcrumbs
              separator="›"
              sx={{
                '& .MuiBreadcrumbs-separator': {
                  color: VEGA_COMMON.primaryBlue,
                },
              }}
            >
              <VegaTextV2
                color={VEGA_COMMON.text200}
                text="Program Management"
                fontSize={12}
                sx={{
                  cursor: 'pointer',
                }}
              />

              <VegaTextV2
                fontSize={12}
                color={VEGA_COMMON.primaryBlue}
                text="Add Program"
              />
            </VegaBreadcrumbs>
          </Stack>
          <Stack
            direction="row"
            gap={1.5}
            alignItems="center"
            onClick={handleGoBackClicked}
            sx={{
              cursor: 'pointer',
              width: 'max-content',
            }}
          >
            <ArrowLeftIcon />
            <PageHeadingTypo
              sx={{
                color: VEGA_COMMON.text200,
              }}
            >
              Add Program
            </PageHeadingTypo>
          </Stack>
        </Stack>
      </Box>
      <Stack
        sx={{
          px: 3.25,
        }}
      >
        <form onSubmit={hookForm.handleSubmit(onSubmit)}>
          <VegaWrapperType1 header="Program Details">
            <Stack direction="row" flexWrap="wrap" gap={2}>
              <Controller
                name="programName"
                control={hookForm.control}
                rules={{ required: 'This field is required' }}
                render={({ field, fieldState }) => (
                  <VegaTextfieldV4
                    header="Program Name"
                    placeholder="Enter Program Name"
                    isRequired
                    {...field}
                    {...(fieldState.error && {
                      error: true,
                      labelText: fieldState.error.message,
                    })}
                  />
                )}
              />
              <Controller
                name="programCode"
                control={hookForm.control}
                rules={{
                  required: 'This field is required',
                  maxLength: 10,
                  minLength: 3,
                  pattern: VALIDATE_REGEX.ALPHA_NUMERIC_UPPER_ONLY,
                }}
                render={({ field, fieldState }) => (
                  <VegaTextfieldV4
                    header="Program Code"
                    placeholder="Enter Program Code"
                    isRequired
                    {...field}
                    {...(fieldState.error && {
                      error: true,
                      labelText: ['maxLength', 'minLength'].includes(
                        fieldState.error.type
                      )
                        ? 'Program code must be between 3-10 characters'
                        : fieldState.error.type === 'pattern'
                        ? 'Program code should not contains lower case or special characters'
                        : fieldState.error.message,
                    })}
                  />
                )}
              />
              <Controller
                name="productType"
                control={hookForm.control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field, fieldState }) => (
                  <VegaSelectV4
                    header="Product Type"
                    placeholder="Select Product Type"
                    options={categoriesOptions}
                    isRequired
                    {...field}
                    value={field.value || ''}
                    {...(fieldState.error && {
                      error: true,
                      labelText: fieldState.error.message,
                    })}
                  />
                )}
              />
              {currentProductOptions && (
                <Controller
                  name="product"
                  control={hookForm.control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field, fieldState }) => (
                    <VegaSelectV4
                      header="Product"
                      placeholder="Select Product"
                      options={currentProductOptions}
                      isRequired
                      {...field}
                      {...(fieldState.error && {
                        error: true,
                        labelText: fieldState.error.message,
                      })}
                    />
                  )}
                />
              )}
              {currentProductType === VegaProgramCategoryType.LOANS ? (
                <Controller
                  name="loanType"
                  control={hookForm.control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field, fieldState }) => (
                    <VegaSelectV4
                      header="Loan Type"
                      placeholder="Select Loan Type"
                      options={currentLoanSectionOptions}
                      isRequired
                      {...field}
                      {...(fieldState.error && {
                        error: true,
                        labelText: fieldState.error.message,
                      })}
                    />
                  )}
                />
              ) : null}
              {[
                VegaProgramCategoryType.CREDIT_CARD,
                VegaProgramCategoryType.FOREX_CARD,
                VegaProgramCategoryType.PREPAID_CARD,
              ].includes(currentProductType) && (
                <>
                  <Controller
                    name="network"
                    control={hookForm.control}
                    rules={{
                      required: 'This field is required',
                    }}
                    render={({ field, fieldState }) => (
                      <VegaSelectV4
                        header="Network"
                        placeholder="Select Network"
                        options={NETWORKS}
                        isRequired
                        {...field}
                        {...(fieldState.error && {
                          error: true,
                          labelText: fieldState.error.message,
                        })}
                      />
                    )}
                  />
                  <Controller
                    name="bin"
                    control={hookForm.control}
                    rules={{
                      required: false,
                    }}
                    render={({ field, fieldState }) => (
                      <VegaSelectV4
                        header="Bin"
                        placeholder="Select Bin"
                        options={filteredBinOptions}
                        {...field}
                        {...(fieldState.error && {
                          error: true,
                          labelText: fieldState.error.message,
                        })}
                      />
                    )}
                  />
                </>
              )}

              <Controller
                name="issuanceType"
                control={hookForm.control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field, fieldState }) => (
                  <VegaSelectV4
                    header="Issuance Type"
                    placeholder="Select Issuance Type"
                    options={issuanceTypeOptions}
                    isRequired
                    {...field}
                    value={field.value || ''}
                    {...(fieldState.error && {
                      error: true,
                      labelText: fieldState.error.message,
                    })}
                  />
                )}
              />
              {currentIssuanceType === VegaProgramIssuanceType.CO_BRAND && (
                <Controller
                  name="coBrandPartnerName"
                  control={hookForm.control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field, fieldState }) => (
                    <VegaSelectV4
                      header="Co-brand Partner"
                      placeholder="Select Co-brand Partner"
                      options={partnerOptions}
                      isRequired
                      {...field}
                      {...(fieldState.error && {
                        error: true,
                        labelText: fieldState.error.message,
                      })}
                    />
                  )}
                />
              )}
            </Stack>
          </VegaWrapperType1>
          <VegaWrapperType1
            header="Agreement & Terms Details (Optional)"
            mt={3}
          >
            <Stack direction="row" flexWrap="wrap" gap={2}>
              <Controller
                name="agreement"
                control={hookForm.control}
                render={({ field, fieldState }) => (
                  <VegaUploadFileInputV4
                    header="Agreement"
                    onFileSelected={(file) => field.onChange(file)}
                    currentFile={field.value}
                    acceptFileType={AcceptFileType.PDF}
                    fileValidator={fileValidatorConfig}
                    error={
                      !!fieldState.error ||
                      !!fileUploadValidationErrors.agreement
                    }
                    onFileSelectedError={(error) =>
                      error &&
                      setFileUploadValidationErrors((prevData) => ({
                        ...prevData,
                        agreement: error,
                      }))
                    }
                  />
                )}
              />
              <Controller
                name="tnc"
                control={hookForm.control}
                render={({ field, fieldState }) => (
                  <VegaUploadFileInputV4
                    header="Terms & Conditions"
                    onFileSelected={(file) => field.onChange(file)}
                    currentFile={field.value}
                    acceptFileType={AcceptFileType.PDF}
                    fileValidator={fileValidatorConfig}
                    labelText={
                      fieldState.error?.message ||
                      fileUploadValidationErrors.tnc
                    }
                    error={
                      !!fieldState.error || !!fileUploadValidationErrors.tnc
                    }
                    onFileSelectedError={(error) =>
                      error &&
                      setFileUploadValidationErrors((prevData) => ({
                        ...prevData,
                        tnc: error,
                      }))
                    }
                  />
                )}
              />
            </Stack>
          </VegaWrapperType1>
          <VegaWrapperType1 header="Maker & Checker (Optional)" mt={3}>
            <Stack direction="row" flexWrap="wrap" gap={2}>
              <Controller
                name="maker"
                control={hookForm.control}
                render={({ field, fieldState }) => (
                  <VegaSelectV4
                    header="Maker"
                    placeholder="Select Maker"
                    options={teamOptions}
                    {...field}
                    value={field.value || ''}
                    {...(fieldState.error && {
                      error: true,
                      labelText: fieldState.error.message,
                    })}
                  />
                )}
              />
              <Controller
                name="checker"
                control={hookForm.control}
                render={({ field, fieldState }) => (
                  <VegaSelectV4
                    header="Checker"
                    placeholder="Select Checker"
                    options={teamOptions}
                    {...field}
                    value={field.value || ''}
                    {...(fieldState.error && {
                      error: true,
                      labelText: fieldState.error.message,
                    })}
                  />
                )}
              />
            </Stack>
          </VegaWrapperType1>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={3}
            pt={5}
            pb={3}
          >
            <VegaButtonV4
              value="Clear"
              type="reset"
              variant="text"
              onClick={() => hookForm.reset()}
            />
            <VegaButtonV4
              value="Add Program"
              type="submit"
              variant="contained"
              loading={isLoading}
            />
          </Stack>
        </form>
      </Stack>
    </VegaPage>
  );
}

export default AddProgram;
