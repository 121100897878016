import { Controller, UseFormReturn } from 'react-hook-form';
import VegaContainerV4 from '../../../common/v4/VegaContainerV4';
import { IAddCustomerFormProps } from './CreateCustomerFormPage';
import { Stack } from '@mui/material';
import { GovernmentIdType } from '../../../../enums/commons';
import VegaSelectV4 from '../../../common/v4/VegaSelectV4';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import VegaTextfieldV4 from '../../../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../../../common/Typography';
import { useEffect, useMemo } from 'react';

interface IAddCustomerIDDetailsProps {
  customerFormHook: UseFormReturn<IAddCustomerFormProps, any>;
}

const AddCustomerIDDetails = ({
  customerFormHook,
}: IAddCustomerIDDetailsProps) => {
  const {
    control,
    formState: { errors },
    watch,
    trigger,
    setValue,
  } = customerFormHook;

  const watchIdType = watch('id.type');
  const watchIdNumber = watch('id.number');

  const idRegex = useMemo(() => {
    switch (watchIdType) {
      case GovernmentIdType.AADHAAR:
        return VALIDATE_REGEX.AADHAAR;
      case GovernmentIdType.DRIVING_LICENCE:
        return VALIDATE_REGEX.DRIVING_LICENCE;
      case GovernmentIdType.PAN:
        return VALIDATE_REGEX.PAN;
    }
  }, [watchIdType]);

  const idHeader = useMemo(() => {
    switch (watchIdType) {
      case GovernmentIdType.AADHAAR:
        return 'Aadhaar ID';
      case GovernmentIdType.DRIVING_LICENCE:
        return 'Licence ID';
      case GovernmentIdType.PAN:
        return 'PAN ID';
    }
  }, [watchIdType]);

  const exampleIdNumber = useMemo(() => {
    switch (watchIdType) {
      case GovernmentIdType.AADHAAR:
        return 'Example Pattern: 2653 8564 4663 / 265385644663';
      case GovernmentIdType.DRIVING_LICENCE:
        return 'Example Pattern: RJ-1320120123456 / RJ13 20120123456 / RJ1320120123456';
      case GovernmentIdType.PAN:
        return 'Example Pattern: ABCPY1234D';
    }
  }, [watchIdType]);

  useEffect(() => {
    if (watchIdNumber) {
      trigger('id.number');
    }
  }, [watchIdType, watchIdNumber, trigger]);

  return (
    <VegaContainerV4 title="ID Details">
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Controller
          name="id.type"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.id?.type)}
              defaultValue=""
              header="ID Type"
              labelText={(errors.id?.type as any)?.message}
              value={rest.value || ([] as any)}
              options={Object.values(GovernmentIdType).map((el) => ({
                name: el,
                id: el,
              }))}
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select ID Type"
            />
          )}
        />
        {Boolean(watchIdType) && idRegex && (
          <>
            <Controller
              name="id.number"
              control={control}
              rules={{
                required: 'Required field',
                pattern: {
                  value: idRegex,
                  message: `Not a valid ${idHeader}`,
                },
              }}
              render={({ field: { ref, ...rest } }) => (
                <VegaTextfieldV4
                  {...rest}
                  error={Boolean(errors.id?.number)}
                  header={idHeader}
                  labelText={
                    errors.id?.number?.message
                      ? errors.id.number.message + `. ${exampleIdNumber}`
                      : exampleIdNumber
                  }
                  onChange={(e) =>
                    setValue('id.number', e.target.value.toUpperCase())
                  }
                  required
                  placeholder={`Enter ${idHeader}`}
                />
              )}
            />
            <Controller
              name="id.nationality"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => (
                <VegaSelectV4
                  {...rest}
                  error={Boolean(errors.id?.nationality)}
                  header="Nationality"
                  labelText={errors.id?.nationality?.message}
                  value={rest.value || ([] as any)}
                  options={['INDIA'].map((el) => ({
                    name: el,
                    id: el,
                  }))}
                  wrapperProps={{
                    sx: {
                      flex: 0,
                    },
                  }}
                  required
                  placeholder="Select Nationality"
                />
              )}
            />
          </>
        )}
      </Stack>
    </VegaContainerV4>
  );
};

export default AddCustomerIDDetails;
