/** @format */

import axios from 'axios';
import { VegaUser, VegaUserStatus } from '../types/user';
import {
  AddCognitoUserForClientParams,
  IRolesProps,
  IUserPaginatedReqProps,
  IUsersProps,
  UpdateUserStatusParams,
} from '../types/userAccess';
import { BASE_URL, ROLE_MANAGEMENT } from './endpoints';

const ROLE_MANAGEMENT_BASE_URL = BASE_URL + ROLE_MANAGEMENT.BASE;

export const fetchListOfUsersForClient = (clientId: string | null) => {
  return axios.get(`${ROLE_MANAGEMENT_BASE_URL}/listUsersByClient/${clientId}`);
};

export const fetchPaginatedListOfUsers = ({
  clientId,
  page,
  size,
}: IUserPaginatedReqProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/listUsers`, {
    clientId,
    page,
    size,
  });
};

export const fetchListOfRolesForClient = (clientId: string | null) => {
  return axios.get(
    `${ROLE_MANAGEMENT_BASE_URL}/listRolesForClient/${clientId}`
  );
};
export const addUserForClient = (user: IUsersProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addUser`, {
    ...user,
    platformId: user.clientId,
    mccUser: false,
    officialEmailId: user.email,
    status: 'ACTIVE',
    isAdmin: true,
    apiScope: {
      currrentLevel: 'CLIENT',
      currentLevelValue: user.clientId,
      levelHierarchy: {
        clientId: user.clientId,
      },
    },
  });
};
export const addRoleForClient = (data: IRolesProps) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addRole`, data);
};
export const updateRoleForClient = (data: any) => {
  return axios.put(`${ROLE_MANAGEMENT_BASE_URL}/updateRole`, data);
};

export const addRoleForUser = (data: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/addRoleForUser`, data);
};
export const updateRoleForUser = (data: any) => {
  return axios.put(`${ROLE_MANAGEMENT_BASE_URL}/updateRoleForUser`, data);
};

export async function getUserByEmail(data: {
  email: string;
}): Promise<VegaUser> {
  const endpoint =
    ROLE_MANAGEMENT_BASE_URL +
    ROLE_MANAGEMENT.GET_BY_EMAIL.replace('{email}', data.email);
  try {
    const response = await axios.get(endpoint);
    return response.data as VegaUser;
  } catch (error) {
    throw error;
  }
}

export async function revokeToken(refreshToken: string) {
  try {
    const endpoint =
      ROLE_MANAGEMENT_BASE_URL + ROLE_MANAGEMENT.REVOKE_REFRESH_TOKEN;
    const response = await axios.post(endpoint, { token: refreshToken });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addCognitoUserForClient(
  data: AddCognitoUserForClientParams
): Promise<VegaUser> {
  const endpoint = ROLE_MANAGEMENT_BASE_URL + ROLE_MANAGEMENT.ADD_COGNITO_USER;
  try {
    const response = await axios.post(endpoint, data);
    return response.data as VegaUser;
  } catch (error) {
    throw error;
  }
}

export const addOrUpdateRolesForUser = (updateUserRoleRequest: any) => {
  return axios.put(
    `${ROLE_MANAGEMENT_BASE_URL}${ROLE_MANAGEMENT.UPDATE_ROLE_FOR_USER}`,
    updateUserRoleRequest
  );
};

////////////////////// Bulk Upload \\\\\\\\\\\\\\\\\\\\
export const bulkProcessForUser = (formData: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/bulk/user/process`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bulkSaveForUser = (data: any) => {
  return axios.post(`${ROLE_MANAGEMENT_BASE_URL}/bulk/user/save`, data);
};

export const fetchPastUploadForUser = (clientId: string) => {
  return axios.post(
    `${ROLE_MANAGEMENT_BASE_URL}/bulk/past/uploads/${clientId}`
  );
};

export const updateUserStatus = ({
  userId,
  status,
}: {
  userId: string;
  status: VegaUserStatus;
}) => {
  return axios.patch(`${ROLE_MANAGEMENT_BASE_URL}/user/status`, {
    userId,
    status,
  });
};
////////////////////// Bulk Upload \\\\\\\\\\\\\\\\\\\\
