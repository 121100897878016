import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../enums/transaction';

import { ChargebackStatus } from './chargeBack';

export interface TransactionToEmiPreview {
  transactionId: string;
  options: EMIPreview[];
}

export interface EMIPreview {
  installmentAmount: number;
  durationInMonths: number;
  interestRate: number;
}
interface TransactionMetaDataDto {
  merchantName: string;
  merchantBrandName: string;
  merchantLogo: string;
  categoryName: string;
  categoryLogo: string;
  categoryDescription: string;
}

export enum TransactionCategory {
  CARD = 'CARD',
  FEE = 'FEE',
  INTEREST = 'INTEREST',
  REPAYMENT = 'REPAYMENT',
  EMI_CONVERTED = 'EMI_CONVERTED',
  UNBILLED_EMI = 'UNBILLED_EMI',
  TRANSFER = 'TRANSFER',
  TCS = 'TCS',
  FOREX = 'FOREX',
}

export interface TransactionDto {
  id: string;
  accountId: string;
  parentTransactionId: string;
  amount: number;
  overlimitUsed: number;
  currency: string;
  transactionIndicator: TransactionIndicator;
  transactionCategory?: TransactionCategory;
  transactionCode: TransactionCode;
  providerTxnId: string;
  merchantName: string;
  narration: string;
  status: TransactionStatus;
  substatus: string;
  transactionTime: string;
  createdAt: string;
  updatedAt: string;
  loanId: string;
  loanConversionDate: string;
  emiConvertible: boolean;
  afterTransactionBalance: number;
  mcc: number;
  mid: number;
  lat: number;
  lng: number;
  attributes: string;
  cardProcessor: string;
  cardId: string;
  merchantId: string;
  metaData: TransactionMetaDataDto;
  merchantLocation: string;
  latestChargebackId: string;
  chargebackStatus: ChargebackStatus;
}

export interface ListTransactionsFilterRequestDto {
  accountId?: string;
  programId?: string;
  parentTransactionId?: string;
  transactionCategory?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  startTime?: string;
  endTime?: string;
  statementDate?: string;
  emiConvertible?: boolean;
  includeMetaData?: boolean;
}

export interface LoanDto {
  id: string;
  account_id: string;
  program_id: string;
  installment_policy_id: any;
  loan_amount: number;
  loan_type: string;
  loan_dispersal_type: any;
  lan: any;
  parent_loan_id: any;
  lender_id: string;
  loan_percentage: number;
  currency: string;
  interest_rate: number;
  interest_type: string;
  interest_amount: number;
  repayment_amount: number;
  installment_amount: any;
  installment_count: number;
  loan_start_date: string;
  over_due_amount: number;
  interest_accure: any;
  due_principal: number;
  application_id: any;
  lending_type: any;
  child_loans: any;
  first_emi_date: any;
  paid_amount: any;
  surplus: number;
  status: string;
  tentative_loan_closing_date: any;
  loan_closed_date: any;
  tenure: number;
  tenure_unit: string;
  first_installment_payment: any;
  installment_frequency: any;
  amortization_type: string;
  gap_interest: any;
  re_amortisation_required: any;
  file_id: any;
  created_at: string;
  updated_at: string;
}
