import { Box, Stack } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import VegaAutocompleteSearch from '../../common/v4/VegaAutocompleteSearchV4';
import SearchIcon from '../../icon/SearchIcon';
import { useBillListQuery } from '../../../store/queries/accountQueries';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import VegaNoResults from '../../common/VegaNoResults';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { Controller, useForm } from 'react-hook-form';
import VegaDatePickerV4 from '../../common/v4/VegaDatePickerV4';
import { generateBillForAccount, getBillPdf } from '../../../api/bill';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getBillListStructuredData } from '../simulatorUtils';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { useCustomerListQuery } from '../../../store/queries/customerQueries';

interface IBillCreationFormProps {
  accId: string;
  date: string;
}

const BillListPage = () => {
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
    setValue: setFormValue,
  } = useForm<IBillCreationFormProps>({
    mode: 'onTouched',
  });

  const { data: customerData, isFetching: isFetchingCustomerData } =
    useCustomerListQuery({
      clientId: clientId as string,
      programId: selectedProgram?.programId as string,
      page: 0,
      pageSize: 20,
      allAccounts: false,
    });

  const customerRecords = useMemo(() => {
    return (
      customerData?.records
        .filter((el) => el?.account?.accountId)
        .map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          id: el.account?.accountId as string,
        })) || []
    );
  }, [customerData]);

  const [value, setValue] = useState<(typeof customerRecords)[0] | undefined>(
    customerRecords[0]
  );

  const { data: ledgerListData, isFetching: isFetchingLedgerListData } =
    useBillListQuery({
      accountId: value?.id as string,
      page,
      pageSize,
    });

  const records = value?.id ? ledgerListData?.records : [];

  const updateSearchValue = (
    e: SyntheticEvent<Element, Event>,
    newVal: any
  ) => {
    setValue(newVal);
    setFormValue('accId', newVal.id);
    setPage(0);
  };

  const handleSubmitClick = () => {
    setIsLoading(true);
    const values = getValues();
    console.log(
      'rec',
      customerData?.records.find((el) => el.account?.accountId === values.accId)
    );
    generateBillForAccount(values.accId, values.date)
      .then((res) => {
        setSnackbar('Bill Generated!');
        reset();
      })
      .catch((err) => {
        setSnackbar('Failed to generate bill', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onViewBillClick = (id: string) => {
    getBillPdf(id).then((res) => {
      const link = res.data?.url;
      window.open(link);
    });
  };

  const structuredData = useMemo(() => {
    return getBillListStructuredData(records, onViewBillClick);
  }, [records]);

  useEffect(() => {
    setValue(customerRecords[0]);
    setFormValue('accId', customerRecords[0]?.id);
  }, [customerData, customerRecords, setFormValue]);

  return (
    <Box>
      <VegaHeaderV4 title="Bills" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={3}>
        <VegaCardV4>
          <Stack direction="row" gap={3}>
            <Controller
              name="accId"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => (
                <VegaAutocompleteSearch
                  textFieldProps={{
                    ...rest,
                    ...TextFieldProps,
                    required: true,
                  }}
                  options={customerRecords}
                  value={value?.label || ''}
                  onChange={updateSearchValue}
                />
              )}
            />
            <Controller
              name="date"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <VegaDatePickerV4
                    dateProps={{ ...rest, views: ['month', 'day'] }}
                    textProps={{
                      header: 'Start Date',
                      required: true,
                      labelText: errors.date?.message,
                      error: Boolean(errors.date),
                      standardWidth: 250,
                      placeholder: 'Select Date',
                      headerTypo: ListGroupHeadingTypo,
                    }}
                  />
                );
              }}
            />
          </Stack>
          <VegaButtonV4
            variant="outlined"
            onClick={handleSubmit(handleSubmitClick)}
            disabled={isLoading}
            loading={isLoading}
          >
            Create Bill
          </VegaButtonV4>
        </VegaCardV4>
        {!records?.length &&
        !(isFetchingLedgerListData || isFetchingCustomerData) ? (
          <VegaNoResults
            errorText={
              value
                ? 'No Bill Found for the customer. Please select another customer'
                : 'No Bill Found. Please select the customer'
            }
          />
        ) : (
          <>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <VegaSearchfieldV4 />
            </Stack>
            <VegaTableV4
              head={[
                'Account ID',
                'Bill Start Date',
                'Bill ID',
                'Due Date',
                'Due Amount',
                'MAD',
                'Status',
                'Action',
              ]}
              data={structuredData}
              isLoading={isFetchingLedgerListData || isFetchingCustomerData}
              pagination={{
                rowsPerPage: pageSize,
                setRowsPerPage: setPageSize,
                setPage,
                page,
                totalRecords: ledgerListData?.totalItems || 0,
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

const TextFieldProps = {
  header: 'customer',
  standardWidth: 250,
  InputProps: { endAdornment: <SearchIcon /> },
  headerTypo: ListGroupHeadingTypo,
};

export default BillListPage;
