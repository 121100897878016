import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import { AddCircleOutline } from '@mui/icons-material';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useEffect, useMemo, useState } from 'react';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import { VegaCreateCustomersType } from '../../../types/customers';
import { useCustomerContext } from './CustomerWrap';
import CustomerDetailsDialog from './CustomerDetailsDialog';
import CustomerViewAuditDialog from './CustomerViewAuditDialog';
import CustomerAccountCreationDrawer from './CustomerAccountCreationDrawer';
import VegaNoResults from '../../common/VegaNoResults';
import { useCustomerListQuery } from '../../../store/queries/customerQueries';

const CustomerListPage = () => {
  const { setCurrState, currState } = useCustomerContext();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [selectedCustomer, setSelectedCustomer] = useState<
    VegaCreateCustomersType | undefined
  >(undefined);
  const [openAuditDialog, setOpenAuditDialog] = useState(false);
  const [openCustomerDetailsDialog, setOpenCustomerDetailsDialog] =
    useState(false);
  const [openCreateAccountDrawer, setOpenCreateAccountDrawer] = useState(false);
  const { selectedProgram } = useProgramData();
  const { data, isFetching, refetch } = useCustomerListQuery({
    programId: selectedProgram?.programId as string,
    clientId: selectedProgram?.clientId as string,
    page,
    pageSize,
    allAccounts: false,
  });

  const records = data?.records;

  const onAuditOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenAuditDialog(true);
  };

  const onAuditClose = () => {
    setOpenAuditDialog(false);
  };

  const onCustomerDetailsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenCustomerDetailsDialog(true);
  };

  const onCustomerDetailsClose = () => {
    setOpenCustomerDetailsDialog(false);
  };

  const onCreateActionsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenCreateAccountDrawer(true);
  };

  const onCreateActionsClose = () => {
    setOpenCreateAccountDrawer(false);
  };

  const go2CreateCustomerPage = () => {
    setCurrState?.('create-customer');
  };

  useEffect(() => {
    refetch();
  }, [currState, refetch]);

  const structuredData: ITableDataProps[] = useMemo(() => {
    return getStructuredData(
      records,
      onAuditOpen,
      onCustomerDetailsOpen,
      onCreateActionsOpen
    );
  }, [records]);

  return (
    <Box>
      <Box paddingTop={4}>
        <VegaHeaderV4
          title="Customers"
          actionBtnProps={{
            variant: 'contained',
            startIcon: <AddCircleOutline fontSize="small" />,
            value: 'Create Customer',
            onClick: go2CreateCustomerPage,
          }}
        />
      </Box>
      {Boolean(records?.length) || isFetching ? (
        <Stack p={3} gap={2}>
          <Stack justifyContent="flex-end" direction="row">
            <VegaSearchfieldV4 />
          </Stack>
          <VegaTableV4
            head={[
              'Customer Name',
              'Mobile Number',
              'Email ID',
              'ID Number',
              'Audit Log',
              'Customer Details',
              'Actions',
            ]}
            data={structuredData}
            isLoading={isFetching}
            pagination={{
              page,
              setPage,
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              totalRecords: data?.totalItems || 0,
            }}
          />
        </Stack>
      ) : (
        <VegaNoResults errorText="No Customers Found" />
      )}
      <CustomerDetailsDialog
        open={openCustomerDetailsDialog}
        onClose={onCustomerDetailsClose}
        selectedCustomer={selectedCustomer}
      />
      <CustomerViewAuditDialog
        open={openAuditDialog}
        onClose={onAuditClose}
        customerId={selectedCustomer?.customerId}
      />
      <CustomerAccountCreationDrawer
        open={openCreateAccountDrawer}
        onClose={onCreateActionsClose}
        selectedCustomer={selectedCustomer}
        refetchCustomerList={refetch}
      />
    </Box>
  );
};

function getStructuredData(
  records: VegaCreateCustomersType[] | undefined,
  onAuditClick: Function,
  onCustomerDetailsClick: Function,
  onCreateActionsClick: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: (rec.firstName || '') + ' ' + (rec.lastName || ''),
            type: 'string',
          },
          {
            value: rec.mobileNumber,
            type: 'string',
          },
          {
            value: rec.emailId,
            type: 'string',
          },
          {
            value: rec.customerId,
            type: 'string',
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onAuditClick,
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onCustomerDetailsClick,
          },
          {
            value: rec.account ? 'Account Created' : 'Create Account',
            type: rec.account ? 'status' : 'link',
            statusColor: 'green',
            linkOnClick: rec.account ? undefined : onCreateActionsClick,
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export default CustomerListPage;
